import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import {
  IFetchTicketsRequest,
  IFetchTicketsResponse,
  ICreateTicketRequest,
  IFetchSubCategoryRequest,
  ICategory,
  ISubCategory,
  IFetchCommentRequest,
  IFetchCommentResponse,
  IFetchAttachmentResponse,
  IFetchUpdateTicketRequest
} from '@/store/modules/tickets/interfaces'
import { ticketsAPI } from '@/store/modules/tickets/api/tickets'
import { TicketsMutationType } from '@/store/modules/tickets/interfaces/mutation-type'
import { ITicketsState } from '@/store/modules/tickets/interfaces/ITicketsState'
import { ITicketsMutations } from '@/store/modules/tickets/interfaces/ITicketsMutations'
import { TicketsActionType } from '@/store/modules/tickets/interfaces/action-type'
import { ITicketsActions } from '@/store/modules/tickets/interfaces/ITicketsActions'

const state = (): ITicketsState => ({
  tickets: {
    ticketsList: [],
    total: 9
  },
  commentsList: {},
  categories: [],
  subCategories: [],
  loading: false,
  currentShipTo: ''
})

const mutations: MutationTree<ITicketsState> & ITicketsMutations = {
  [TicketsMutationType.SET_LOADING] (state: ITicketsState, payload: boolean) {
    state.loading = payload
  },
  async [TicketsMutationType.SET_TICKETS] (state: ITicketsState, payload: IFetchTicketsResponse) {
    state.tickets = payload
  },
  async [TicketsMutationType.SET_CATEGORIES] (state: ITicketsState, payload: ICategory[]) {
    state.categories = payload
  },
  async [TicketsMutationType.SET_SUBCATEGORIES] (state: ITicketsState, payload: ISubCategory[]) {
    state.subCategories = payload
  },
  async [TicketsMutationType.SET_COMMENTS_FOR_TICKET] (
    state: ITicketsState,
    payload: IFetchCommentRequest & IFetchCommentResponse
  ) {
    const ticketIndex = state.tickets.ticketsList.findIndex((i) => i.caseNumber === payload.ticketId)
    if (ticketIndex >= 0) state.tickets.ticketsList[ticketIndex].comments = payload.commentsList
    state.commentsList[payload.ticketId] = payload.commentsList
  },
  async [TicketsMutationType.SET_ATTACHMENTS_FOR_TICKET] (
    state: ITicketsState,
    payload: IFetchAttachmentResponse & IFetchCommentRequest
  ) {
    const ticketIndex = state.tickets.ticketsList.findIndex((i) => i.caseNumber === payload.ticketId)
    if (ticketIndex >= 0) state.tickets.ticketsList[ticketIndex].attachments = payload.attachmentList
  }
}

const actions: ActionTree<ITicketsState, IRootState> & ITicketsActions = {
  async [TicketsActionType.FETCH_TICKETS] ({ commit }, dto: IFetchTicketsRequest) {
    commit(TicketsMutationType.SET_LOADING, true)
    const data = await ticketsAPI.fetchAllTickets(dto)
    commit(TicketsMutationType.SET_TICKETS, data)
    commit(TicketsMutationType.SET_LOADING, false)
  },
  async [TicketsActionType.FETCH_CATEGORIES] ({ commit }) {
    commit(TicketsMutationType.SET_LOADING, true)
    const data = await ticketsAPI.fetchCategories()
    commit(TicketsMutationType.SET_CATEGORIES, data)
    commit(TicketsMutationType.SET_LOADING, false)
  },
  async [TicketsActionType.FETCH_SUBCATEGORIES] ({ commit }, dto: IFetchSubCategoryRequest) {
    commit(TicketsMutationType.SET_LOADING, true)
    const data = await ticketsAPI.fetchSubCategories(dto.categoryId)
    commit(TicketsMutationType.SET_SUBCATEGORIES, data)
    commit(TicketsMutationType.SET_LOADING, false)
  },
  async [TicketsActionType.FETCH_INFO_FOR_TICKET] ({ commit }, dto: IFetchCommentRequest) {
    commit(TicketsMutationType.SET_LOADING, true)
    const commentResponse = await ticketsAPI.fetchCommentsByTicket(dto.ticketId, dto.limit)
    // const attachmentResponse = await ticketsAPI.fetchAttachmentsByTicket(dto.ticketId)
    await Promise.all([commentResponse])
      .then(async (result) => {
        const comments = await result[0].commentsList
        commit(TicketsMutationType.SET_COMMENTS_FOR_TICKET, { commentsList: comments, ticketId: dto.ticketId })

        // const attachments = result[1].attachmentList
        // commit(TicketsMutationType.SET_ATTACHMENTS_FOR_TICKET, { attachmentList: attachments, ticketId: dto.ticketId })
      })
      .finally(() => commit(TicketsMutationType.SET_LOADING, false))
  },
  async [TicketsActionType.CREATE_TICKET] ({ commit }, dto: ICreateTicketRequest) {
    commit(TicketsMutationType.SET_LOADING, true)
    await ticketsAPI.createTicket(dto)
    commit(TicketsMutationType.SET_LOADING, false)
  },
  async [TicketsActionType.READ_COMMENT] ({ commit }, dto: IFetchCommentRequest) {
    commit(TicketsMutationType.SET_LOADING, true)
    try {
      await ticketsAPI.readCommentsByTicket(dto)
    } catch (error) {
      console.error('Error in READ_COMMENT action:', error) // Log errors
    } finally {
      commit(TicketsMutationType.SET_LOADING, false)
    }
  },
  async [TicketsActionType.FETCH_UPDATE_TICKET] ({ commit }, dto: IFetchUpdateTicketRequest) {
    commit(TicketsMutationType.SET_LOADING, true)

    if (dto.user && dto.description && dto.ticketId) {
      await ticketsAPI.fetchCreateComment({
        description: dto.description,
        ticketId: dto.ticketId,
        user: dto.user
      })
    }
    if (dto.attachments) await ticketsAPI.fetchUploadAttachment(dto)
    commit(TicketsMutationType.SET_LOADING, false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
