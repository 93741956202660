import { AxiosResponse } from 'axios'
import { generalServiceV1API } from '@/store/api-instance'
import { IReportingRequest } from '@/store/modules/reporting/interfaces/api/IReportingRequest'
import { IReportingResponse } from '@/store/modules/reporting/interfaces/api/IReportingResponse'

export const reportingAPI = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getPowerBIDetails (dto: IReportingRequest): Promise<IReportingResponse> {
    const response: AxiosResponse<IReportingResponse> = (await generalServiceV1API.get(
      '/power-bi/sales-report'
    )) as AxiosResponse<IReportingResponse>
    return response.data
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getContractsPowerBIDetails (dto: IReportingRequest): Promise<IReportingResponse> {
    const response: AxiosResponse<IReportingResponse> = (await generalServiceV1API.get(
      '/power-bi/contract-evaluation'
    )) as AxiosResponse<IReportingResponse>
    return response.data
  }
}
