
import { computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import AsahiArrow from '@/components/library/assets/AsahiArrow.vue'
import { IHelpPages } from '@/store/modules/help-pages/interfaces'

export default defineComponent({
  name: 'DropDown',
  components: {
    AsahiArrow
  },
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    options: {
      type: Array as PropType<Array<IHelpPages>>,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    defaultLabel: {
      type: String,
      default: 'Select an option'
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      openDropdown: false
    }
  },
  computed: {
    displayLabel () {
      return (this.modelValue + 1) + '. ' + this.options[this.modelValue]?.section
    }
  },
  methods: {
    toggleDropdown () {
      this.openDropdown = !this.openDropdown
    },

    selectValue (idx: number) {
      // emit('update:modelValue', option.value)
      this.openDropdown = false
      this.$emit('update:modelValue', idx)
    },

    handleClickOutside () {
      this.openDropdown = false
    }
  }

})
