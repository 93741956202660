import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-5 asahi-tab-select"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "tab-counter"
}
const _hoisted_4 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiSelectBox = _resolveComponent("AsahiSelectBox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.dynamicClassName)
  }, [
    (_ctx.displayType === 'select')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AsahiSelectBox, {
            "h-fill-option": true,
            "full-width": true,
            modelValue: _ctx.internalCurrentTab,
            options: _ctx.tabListData,
            style: {"width":"100%"},
            color: "gold",
            icon: _ctx.tabs[_ctx.internalCurrentTab].icon,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => { _ctx.handleTabClick(value) })
          }, null, 8, ["modelValue", "options", "icon"])
        ]))
      : (_openBlock(), _createElementBlock("ul", {
          key: 1,
          class: _normalizeClass(["tabs pt-2 pt-lg-0", {'pdpTabs': _ctx.pdpTabs}])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["tab tab-label", {
          'active': _ctx.internalCurrentTab === index,
          'active-gold': _ctx.internalCurrentTab === index && _ctx.activeColor === 'gold',
          'inactive': _ctx.internalCurrentTab !== index && _ctx.inactiveColorClass
        }]),
              key: index,
              onClick: ($event: any) => (_ctx.handleTabClick(index))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["text-uppercase", _ctx.isMobile ? 'tab-text-mobile px-1' : 'tab-text px-2'])
              }, _toDisplayString(tab.label), 3),
              (tab?.counter > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(tab.counter) + ")", 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_2))
          }), 128))
        ], 2)),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTabComponent), {
        onEmitChildMethod: _ctx.emitChildMethod,
        "search-type": _ctx.searchValue,
        onCloseModal: _ctx.emitChildMethod
      }, null, 40, ["onEmitChildMethod", "search-type", "onCloseModal"]))
    ])
  ], 2))
}