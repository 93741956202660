import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-455eaa4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-box" }
const _hoisted_2 = {
  key: 0,
  class: "select-label"
}
const _hoisted_3 = { class: "d-flex justify-content-between gap-2" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "arrow-container" }
const _hoisted_6 = {
  key: 0,
  class: "triangle-up"
}
const _hoisted_7 = {
  key: 1,
  class: "options-container py-3 w-100"
}
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiArrow = _resolveComponent("AsahiArrow")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "selected-option d-flex",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", { title: _ctx.displayLabel }, _toDisplayString(_ctx.displayLabel || _ctx.defaultLabel), 9, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AsahiArrow, {
            "is-rotated": !_ctx.openDropdown,
            class: "invert"
          }, null, 8, ["is-rotated"])
        ])
      ]),
      (_ctx.openDropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.openDropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (helpPage, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (_ctx.selectValue(index)),
                class: "w-100"
              }, _toDisplayString(index + 1) + ". " + _toDisplayString(helpPage?.section), 9, _hoisted_8))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}