<script>
import AsahiButton from '@/components/library/AsahiButton.vue'

export default {
  name: 'AsahiBsSearchSupport',
  components: {
    AsahiButton
  },
  props: {
    searchType: {
      type: String
    }
  },
  computed: {
    faqKnowledgeBase () {
      return this.$store.state.support.newAllKbsAsync
    },
    faqPages () {
      return this.$store.state.support.faqPages
    },
    isMobile () {
      return window.innerWidth <= 768
    }
  }
}
</script>

<template v-if='faqKnowledgeBase?.length > 0 || faqPages?.length > 0'>
  <!-- <h6>{{ $t('Knowledge Base') }}</h6> -->
  <!-- <div class='asahi-search-support-container'> -->
    <div class="row">
      <div v-for="(item, index) in faqKnowledgeBase" :key="item.id" :class="isMobile ? 'col-6': 'col-3'">
        <template v-if="index < 4">
          <div class="asahi-search-results-item">
            <div class="asahi-search-results-item-img">
              <img :src="item?.contentImage?.link" />
            </div>
            <p>
              <span v-html="item?.title?.replace(RegExp('(' + searchValue + ')', 'gi'), '<b>$1</b>')"></span>
            </p>
          </div>
        </template>
      </div>
    </div>
  <!-- </div> -->
  <!-- <h6>{{ $t('FAQ') }}</h6> -->
  <!-- <div class='asahi-search-support-container'> -->
    <div class="row">
      <div v-for="(item, index) in faqPages" :key="item.id" class="col-3">
        <template v-if="index < 4">
          <div class="asahi-search-results-item">
            <div class="asahi-search-results-item-img">
              <img src="@/components/library/assets/Open-ticket.svg" />
            </div>
            <p>
              <span v-html="item?.title?.replace(RegExp('(' + searchValue + ')', 'gi'), '<b>$1</b>')"></span>
            </p>
          </div>
        </template>
      </div>
    </div>
  <!-- </div> -->
  <div class="row justify-content-center asahi-tabs-container-cta">
    <div v-if='faqPages?.length <= 0 && faqKnowledgeBase?.length <= 0' class='fw-bold d-flex justify-content-center mb-4'>{{$t('No Result Found')}}</div>
    <div class="col-auto">
      <router-link to="/support/tickets">
          <AsahiButton type="secondary">{{ $t('All results in this section') }}</AsahiButton>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang='scss'>
h6{
  padding: $gap/2 $gap;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  line-height: $gap;
}
.asahi-search-support-container {
  border-top: 2px solid $header-secondary-gray;
  padding: $gap/2 0 $gap;
}
.asahi-search-results-item p {
  text-align: left;
}
</style>
