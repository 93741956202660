export enum OrderActionType {
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_SAVED_ORDERS = 'FETCH_SAVED_ORDERS',
  FETCH_SAVED_ORDER_DETAILS = 'FETCH_SAVED_ORDER_DETAILS',
  REORDER_SAVED_ORDER = 'REORDER_SAVED_ORDER',
  DELETE_SAVED_ORDER = 'DELETE_SAVED_ORDER',
  REORDER_HISTORY_ORDER = 'REORDER_HISTORY_ORDER',
  CANCEL_HISTORY_ORDER = 'CANCEL_HISTORY_ORDER',
  FETCH_SUBMITTED_ORDER_DETAILS = 'FETCH_SUBMITTED_ORDER_DETAILS',
  SAVE_CUSTOMER_ORDER_NUMBER = 'SAVE_CUSTOMER_ORDER_NUMBER'
}
