import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid page" }
const _hoisted_2 = { class: "page__content" }
const _hoisted_3 = { class: "page__header" }
const _hoisted_4 = { class: "asahi-logo-header" }
const _hoisted_5 = { class: "d-flex align-items-center justify-content-end primary-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiLogoNew = _resolveComponent("AsahiLogoNew")!
  const _component_AsahiLanguageSelector = _resolveComponent("AsahiLanguageSelector")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AsahiLogoNew, {
            onClick: _cache[0] || (_cache[0] = () => _ctx.$navigate('/login'))
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          (!!_ctx.activeLanguage)
            ? (_openBlock(), _createBlock(_component_AsahiLanguageSelector, {
                key: 0,
                languages: _ctx.languages,
                activeLanguage: _ctx.activeLanguage,
                onLanguagechange: _cache[1] || (_cache[1] = (event) => _ctx.changeLanguage(event))
              }, null, 8, ["languages", "activeLanguage"]))
            : _createCommentVNode("", true),
          _createVNode(_component_AsahiButton, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$navigate('/login'))),
            class: "login-button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Login')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}