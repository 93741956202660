
import { computed, defineComponent, ref, watch } from 'vue'
import type { PropType, Ref } from 'vue'
import AsahiArrow from '@/components/library/assets/AsahiArrow.vue'
import BasicModal from '@/components/modals/BasicModal.vue'
import OptionsModal from '@/components/modals/OptionsModal.vue'
import AsahiRadioButton from '@/components/library/AsahiRadioButton.vue'

interface Option {
  value: string | number
  label: string
  disabled?: boolean
  icon?: string
  description?: string
}

export default defineComponent({
  name: 'AsahiSelectHeader',
  components: { AsahiRadioButton, AsahiArrow, BasicModal, OptionsModal },
  props: {
    modelValue: {
      required: true
    },
    placeholder: {
      type: String
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true
    },
    class: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String
    },
    defaultLabel: {
      type: String,
      default: 'Select an option'
    },
    isValid: {
      type: Boolean,
      default: true
    },
    isTicket: {
      type: Boolean,
      default: true
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    noOffset: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    selectedModal: {
      type: Boolean,
      default: false
    },
    title: String,
    isDatePickerOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const openDropdown = ref(false)
    const showSelectedModal = ref(false)
    const showOptionsModal = ref(false)

    watch(() => props.isDatePickerOpen, (newValue) => {
      openDropdown.value = false
    })

    const handleContainerClick = () => {
      if (props.selectedModal) {
        setSelectedModalTo(true)
      } else {
        setOptionsModalTo(true)
      }
    }

    const toggleDropdown = () => {
      openDropdown.value = !openDropdown.value
    }

    const setSelectedModalTo = (value: boolean) => {
      showSelectedModal.value = value
    }

    const setOptionsModalTo = (value: boolean) => {
      showOptionsModal.value = value
    }

    const selectOption = (option: Option) => {
      if (!option.disabled) {
        emit('update:modelValue', option.value)
        openDropdown.value = false
      }
    }

    const selectedOption = computed<Option | undefined>(() => {
      return props.options.find(option => option.value === props.modelValue)
    })

    const selectedLabel = computed(() => {
      return selectedOption.value?.label || ''
    })

    const displayLabel = computed(() => {
      return selectedLabel.value
    })

    const selectedOptionIcon = computed(() => {
      return selectedOption.value?.icon
    })

    const fixedIcon = computed(() => {
      return selectedLabel.value === 'Select Status' ? require('@/components/library/assets/BlackAlarm.svg') : require('@/components/library/assets/icons.svg')
    })

    const filteredOptions = computed(() => {
      return props.options.filter(option => (option.label !== 'Ticket Status') && (option.label !== 'Select ShipTo'))
    })

    return {
      openDropdown,
      showSelectedModal,
      showOptionsModal,
      toggleDropdown,
      setSelectedModalTo,
      setOptionsModalTo,
      selectOption,
      handleContainerClick,
      selectedLabel,
      displayLabel,
      selectedOptionIcon,
      selectedOption,
      fixedIcon,
      filteredOptions
    }
  },
  methods: {
    handleClickOutside () {
      this.openDropdown = false
    }
  }
})
