import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IUsersState } from '@/store/modules/users/interfaces/IUsersState'
import { UsersMutationType } from '@/store/modules/users/interfaces/mutation-type'
import { IUsersMutations } from '@/store/modules/users/interfaces/IUsersMutation'
import { IUsersActions } from '@/store/modules/users/interfaces/IUsersAction'
import { UserActionType } from '@/store/modules/users/interfaces/action-type'
import { IFetchUsersRequest } from '@/store/modules/users/interfaces/users/api/fetchUsers/IFetchUsersRequest'
import { usersAPI } from '@/store/modules/users/api/getUsers'
import { saveUserAPI } from '@/store/modules/users/api/saveUser'
import { IFetchUsersResponse } from '@/store/modules/users/interfaces/users/api/fetchUsers/IFetchUsersResponse'
import { IFetchSelectedUserRequest } from '@/store/modules/users/interfaces/selectedUser/api/fetchSelectedUser/IFetchSelectedUserRequest'
import { IFetchSelectedUserResponse } from '@/store/modules/users/interfaces/selectedUser/api/fetchSelectedUser/IFetchSelectedUserResponse'
import { ISaveUserRequest } from '@/store/modules/users/interfaces/selectedUser/api/saveUser/ISaveUserRequest'
import { IUser } from '@/store/modules/users/interfaces/IUser'
import { ISaveUserChangePasswordRequest } from '@/store/modules/users/interfaces/selectedUser/api/saveUser/ISaveUserChangePasswordRequest'
import { IFetchPayerDetailsRequest } from '@/store/modules/users/interfaces/selectedUser/api/fetchPayer/IFetchPayerDetailsRequest'
import { IFetchPayerDetailsResponse } from '@/store/modules/users/interfaces/selectedUser/api/fetchPayer/IFetchPayerDetailsResponse'
import { IPayerDetails } from './interfaces/selectedUser/api/fetchPayer/IPayerDetails'
import { ISetUserActivateDeactivateRequest } from './interfaces/users/api/fetchUsers/ISetUserActivateDeactivateRequest'
import { IPayerSoldTo } from '@/store/modules/users/interfaces/selectedUser/api/fetchPayer/IPayerSoldTo'
import { ISaveUserResponse } from '@/store/modules/users/interfaces/selectedUser/api/saveUser/ISaveUserResponse'
import { IResendVerificationEmailRequest } from './interfaces/selectedUser/api/saveUser/IResendVerificationEmailRequest'
import { IAddDefaultShipToRequest } from './interfaces/selectedUser/api/fetchPayer/IAddDefaultShipToRequest'

const state = (): IUsersState => ({
  users: {
    totalCount: 0,
    results: []
  },
  selectedUser: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    isActive: false,
    phone: '',
    country: '',
    languages: '',
    locale: '',
    termsAgreed: false,
    createdAt: '',
    permissionSchema: [],
    userValidation: '',
    deletedShipToList: []
  },
  payerDetails: {} as IFetchPayerDetailsResponse,
  payerSoldTo: {} as IPayerSoldTo,
  isUserSaved: {} as ISaveUserResponse,
  isUserActivatedDeactivated: false,
  loggedInUser: {} as IUser,
  isPasswordChanged: false,
  loading: false,
  resendVerificationMailSent: false,
  addDefaultShipToStatus: false
})

const getters = {
  getShipTos (state: IUsersState) {
    return state.payerDetails.payers
  },
  getEmployees (state: IUsersState) {
    return state.payerDetails.employees
  },
  getCompanyDetails: (state: IUsersState) => () => {
    let companyDetails = {} as IPayerDetails
    if (state.payerDetails?.payerdetail) {
      // This code written for IT market
      companyDetails = state.payerDetails?.payerdetail
    } else if (state.payerDetails?.payers?.length) {
      companyDetails = state.payerDetails.payers[0]
    }
    return companyDetails
  }
}

const mutations: MutationTree<IUsersState> & IUsersMutations = {
  [UsersMutationType.SET_LOADING] (state: IUsersState, payload: boolean) {
    state.loading = payload
  },
  async [UsersMutationType.SET_USERS] (state: IUsersState, payload: IFetchUsersResponse) {
    state.users = payload
  },
  async [UsersMutationType.SET_SELECTED_USER] (state: IUsersState, payload: IFetchSelectedUserResponse) {
    state.selectedUser = payload
  },
  async [UsersMutationType.SET_IS_USER_SAVED] (state: IUsersState, payload: ISaveUserResponse) {
    state.isUserSaved = payload
  },
  async [UsersMutationType.SET_LOGGED_IN_USER] (state: IUsersState, payload: IFetchSelectedUserResponse) {
    state.loggedInUser = payload
  },
  async [UsersMutationType.SET_IS_PASSWORD_CHANGED] (state: IUsersState, payload: boolean) {
    state.isPasswordChanged = payload
  },
  async [UsersMutationType.SET_PAYER_DETAILS] (state: IUsersState, payload: IFetchPayerDetailsResponse) {
    state.payerDetails = payload
  },
  async [UsersMutationType.SET_DEFAULT_SHIP_TO] (state: IUsersState, payload: boolean) {
    state.addDefaultShipToStatus = payload
  },
  async [UsersMutationType.SET_USER_STATUS] (state: IUsersState, payload: boolean) {
    state.isUserActivatedDeactivated = payload
  },
  async [UsersMutationType.SET_PAYER_SOLD_TO] (state: IUsersState, payload: IPayerSoldTo) {
    state.payerSoldTo = payload
  },
  async [UsersMutationType.SET_VERIFICATIION_MAIL_SENT] (state: IUsersState, payload: boolean) {
    state.resendVerificationMailSent = payload
  }
}

const actions: ActionTree<IUsersState, IRootState> & IUsersActions = {
  async [UserActionType.FETCH_USERS] ({ commit }, dto: IFetchUsersRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await usersAPI.fetchUsers(dto)
    commit(UsersMutationType.SET_USERS, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.FETCH_SELECTED_USER] ({ commit }, dto: IFetchSelectedUserRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await usersAPI.fetchSelectedUser(dto)
    commit(UsersMutationType.SET_SELECTED_USER, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.SAVE_USER] ({ commit }, dto: ISaveUserRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await saveUserAPI.createUser(dto)
    commit(UsersMutationType.SET_IS_USER_SAVED, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.UPDATE_USER] ({ commit }, dto: ISaveUserRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await saveUserAPI.updateUser(dto)
    commit(UsersMutationType.SET_IS_USER_SAVED, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.CHANGE_PASSWORD] ({ commit, rootState }, dto: ISaveUserChangePasswordRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await saveUserAPI.changePassword(dto, rootState)
    commit(UsersMutationType.SET_IS_PASSWORD_CHANGED, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.FETCH_PAYER_DETAILS] ({ commit }, dto: IFetchPayerDetailsRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await usersAPI.fetchPayerDetails(dto)
    commit(UsersMutationType.SET_PAYER_DETAILS, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.ADD_DEFAULT_SHIP_TO] ({ commit }, dto: IAddDefaultShipToRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await usersAPI.addDefaultShipTo(dto)
    commit(UsersMutationType.SET_DEFAULT_SHIP_TO, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.FETCH_PAYER_DETAILS_IT] ({ commit }, dto: IFetchPayerDetailsRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await usersAPI.fetchPayerDetailsIT(dto)
    commit(UsersMutationType.SET_PAYER_DETAILS, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.SET_USER_STATUS] ({ commit }, dto: ISetUserActivateDeactivateRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await saveUserAPI.setUserStatus(dto)
    commit(UsersMutationType.SET_USER_STATUS, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.FETCH_PAYER_SOLD_TO] ({ commit }, dto: IFetchPayerDetailsRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await usersAPI.fetchPayerSoldTo(dto)
    commit(UsersMutationType.SET_PAYER_SOLD_TO, data)
    commit(UsersMutationType.SET_LOADING, false)
  },
  async [UserActionType.RESEND_VERIFICATION_MAIL] ({ commit }, dto: IResendVerificationEmailRequest) {
    commit(UsersMutationType.SET_LOADING, true)
    const data = await saveUserAPI.resendVerificationEmail(dto)
    commit(UsersMutationType.SET_VERIFICATIION_MAIL_SENT, data)
    commit(UsersMutationType.SET_LOADING, false)
  }
}

const UsersModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default UsersModule
