<template>
  <div
    v-click-outside="handleClickOutside"
    class="asahi-select-container"
    :class="{'no-broder' : showOnHover}"
    @click="toggleDropdown"
  >
    <div
      @mouseover="showToolTip(true)"
      @mouseleave="showToolTip(false)"
      :class="{'d-inline-flex':showOnHover}">
      <div
        class="asahi-icon-container"
        v-if="iconUrl" :class="{'absolute' : !showOnHover , 'showOnHover' : showOnHover}">
        <img :src='iconUrl' />
      </div>
      <div class="asahi-select-title me-4" :class="{'showOnHover' : showOnHover, 'ms-4 ps-2': !!iconUrl}">
        {{selectedText}}
      </div>
      <div v-if="showArrow" class="arrow" :class="toggle ? 'up' : 'down'">
        <img :src='arrow' class="arrow-icon"/>
      </div>
    </div>

    <div class="asahi-select-content">
      <AsahiToolTip v-if='openTooltip === true && toggle === false'>
        <template v-slot:body>
          <div>{{getShipToDetails?.text}}</div>
          <div>{{getShipToDetails?.longText}}</div>
        </template>
      </AsahiToolTip>
    </div>
    <div class="asahi-select-content">
      <AsahiToolTip v-show="toggle && options">
        <template v-slot:body>
          <AsahiRadioButton
            :name="name"
            :selectedValue="selectedOptionValue"
            :options="options"
            @chooseOption="chooseOption"/>
        </template>
      </AsahiToolTip>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import AsahiRadioButton from '@/components/library/AsahiRadioButton.vue'
import AsahiToolTip from '@/components/library/AsahiToolTip.vue'
import arrow from '@/assets/icons/library/arrow.svg'
import arrowwhite from '@/assets/icons/library/arrowwhite.svg'
import arrowgold from '@/assets/icons/library/arrowgold.svg'

export default defineComponent({
  name: 'AsahiSelect',
  components: {
    AsahiRadioButton,
    AsahiToolTip
  },
  emits: ['input', 'update:shipTo'],
  props: {
    selectText: {
      type: String,
      default: 'Select'
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedOptionValue: {
      type: String,
      default: ''
    },
    iconUrl: {
      type: String,
      default: ''
    },
    iconUrlHover: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    showOnHover: {
      type: Boolean,
      default: false
    },
    shipToId: {
      type: String,
      default: ''
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedOption: '',
      toggle: false,
      arrow,
      arrowwhite,
      arrowgold,
      openTooltip: false,
      selectedShipTo: ''
    }
  },
  computed: {
    selectedText () {
      if (this.showOnHover) {
        return this.getShipToDetails?.text
      } else {
        return this.selectedOption ? this.selectedOption : this.selectText
      }
    },

    getShipToDetails () {
      const shipToId = this.selectedShipTo ? this.selectedShipTo : this.shipToId
      const shipToData = this.options.filter(item => item.value === shipToId)
      return shipToData ? shipToData[0] : []
    }
  },
  methods: {
    chooseOption (data) {
      this.selectedOption = data.label
      this.selectedShipTo = data.value
      this.$emit('input', data.value)
      this.$emit('update:shipTo', data.value)
      this.toggleDropdown()
    },
    toggleDropdown () {
      this.toggle = !this.toggle
    },
    showToolTip (value) {
      if (this.showOnHover) {
        this.openTooltip = value
      }
    },
    handleClickOutside () {
      this.toggle = false
    }
  }
})
</script>

<style scoped lang='scss'>
.asahi-select-container {
  position: relative;
  border: 1px solid $border-grey;
  padding: 8px;
  cursor: pointer;
  border-radius: 25px;
  margin: 10px 0;
  .d-inline-flex {
    align-items: center;
    gap: $gap/4;
    img {
      filter: brightness(0);
      @include media-breakpoint-up(lg) {
        filter: brightness(0) invert(1);
      }
    }
    .arrow {
      position: static;
      top: auto;
    }
  }
}

.asahi-select-title {
  text-align: left;
  display: block;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-up(xl) {
    display: block;
  }
}

.asahi-select-content {
  position: absolute;
  top: 50px;
  left: 0;
}

.asahi-icon-container {
  &.absolute {
    position: absolute;
    top: 8px;
    left: 17px;
  }
}
.arrow {
  position: absolute;
  right: 20px;
}

.arrow.down {
  top: 11px;
  transform: rotate(180deg);
}
.arrow.up {
  top: 7px;
  transform: rotate(0deg);
}
.showOnHover {
  color: $primary-black;
  @include media-breakpoint-up(lg) {
    color: $primary-white
  }
}
.showOnHover:hover {
  color: $primary-gold,
}
.no-broder {
  border: none;
}
.arrow-icon {
  fill: $primary-black;
  @include media-breakpoint-up(lg) {
    fill: $primary-white
  }
}
</style>
