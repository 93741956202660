<template>
<div  class="toaster-conatiner" v-if="isToastVisible">
  <div class="toast
    align-items-center pt-2 pb-2"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :data-bs-delay="delayHide"
    :class="{ 'show': showToastValue }">
      <div class="d-flex">
        <img v-if="iconType === 'success'" :src="greentick" class="mt-2 ms-3"/>
        <div class="toast-body">
          <slot name="body"></slot>
        </div>
        <AsahiButton type="bicon" data-bs-dismiss="toast" @click="hideToast">
          <img :src="close"/>
        </AsahiButton>
      </div>
    </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import greentick from '@/assets/icons/library/greentick.svg'
import AsahiButton from '@/components/library/AsahiButton.vue'
import close from '@/components/library/assets/close.svg'

export default defineComponent({
  name: 'AsahiToast',
  components: {
    AsahiButton
  },
  emits: ['hideToast'],
  props: {
    isToastVisible: {
      type: Boolean,
      default: false
    },
    productName: {
      type: String,
      default: ''
    },
    titleText: {
      type: String
    },
    delayHide: {
      type: Number,
      default: 5000
    },
    iconType: {
      type: String,
      default: 'success'
    }
  },
  data () {
    return {
      greentick,
      close,
      showToastValue: false
    }
  },
  mounted () {
    this.showToast()
  },
  methods: {
    showToast () {
      this.showToastValue = true
      setTimeout(() => {
        this.hideToast()
      }, this.delayHide)
    },
    hideToast () {
      this.showToastValue = false
      this.$emit('hideToast')
    }
  }
})
</script>

<style scoped lang='scss'>
.toaster-conatiner {

   position: fixed;
   top: 80px;
   right: 10px;
   width:auto;
   z-index: 100;
   border: 1px solid $primary-medium-green;
   border-radius:8px;
}

.toast {
    width: auto;
    background-color: $background-green;
}

</style>
