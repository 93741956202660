import gql from 'graphql-tag'

export const fetchSavedOrdersQuery = gql`
  query (
    $sap_id: [String!],
    $date_from: String,
    $date_to: String,
    $deliver_to: String,
    $bill_to: String,
    $currentPage: Int,
    $shipto_name: String,
    $pageSize: Int = 10,
    $sortOrder: SaveOrderSortInput,
    $customer_order_number: String,
    $type_of_transport: String
  ) {
    getSaveOrderForLater(
      deliver_to: $deliver_to,
      shipto_name: $shipto_name,
      bill_to: $bill_to,
      date_from: $date_from,
      date_to: $date_to,
      sap_id: $sap_id,
      pageSize: $pageSize,
      currentPage: $currentPage,
      sortOrder: $sortOrder,
      customer_order_number: $customer_order_number,
      type_of_transport: $type_of_transport
    ) {
      page_info {
        total_unique_items
        total_items
        total_pages
      }
      items {
        id
        date
        total
        total_qty
        deliver_to
        shipto_name
        bill_to
        saved_order_later
        is_active
        cart_id
        outlet_id
        customer_order_number
        type_of_transport
      }
    }
  }
`

export const fetchSavedOrdersDetailQuery = gql`
  query ($cart_id: String!) {
    getSaveOrderDetails(cart_id: $cart_id, saved_for_later: true) {
      email
      transporttype
      ship_to_id
      delivery_date
      id
      grand_total_your_price_tax
      grand_total_your_price
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          error_message
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      items {
        ... on CartItemInterface {
          product {
            number_of_products_on_layer
            number_of_items_on_pallet
            number_of_layers_per_pallet
            name
            sku
            volume
            small_image {
              url
            }
          }
          quantity
          prices {
            your_price
            your_price_tax
            total_your_price_tax
          }
          errors {
            code
            message
          }
        }
        ... on SimpleCartItem {
          quantity_total
          number_of_packages
          number_of_layer
          number_of_layer_total
          number_of_pallet

        }
      }
      selected_payment_method {
        code
        title
      }
      prices {
        grand_total {
          value
          currency
        }
      }
    }
  }
`
