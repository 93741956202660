import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c6471de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "modal-component-overlay",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backdropDismiss ? _ctx.close() : null))
        }),
        _createElementVNode("div", {
          class: _normalizeClass(['modal-component', _ctx.useOverflowStyle ? 'overflow-y' : '', _ctx.isFullScreenMobile ? 'full-screen-mobile' : '']),
          style: _normalizeStyle({ width: _ctx.width, height: _ctx.height })
        }, [
          (_ctx.showCloseButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "modal-component-close",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["modal-component-overflow-wrapper", [_ctx.isFullScreenMobile ? 'full-screen-mobile' : '']])
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2)
        ], 6)
      ]))
    : _createCommentVNode("", true)
}