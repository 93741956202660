import gql from 'graphql-tag'

export const saveCustomerOrderNumberMutation = gql`
  mutation ($cart_id: String!, $customer_order_number: String!) {
    saveCustomerOrderNumber(
      input: {
        cart_id: $cart_id,
        customer_order_number: $customer_order_number
      }
    ) {
      message
      customer_order_number
    }
  }
`
