import gql from 'graphql-tag'

export const fetchMinCartForCatalogQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  ) {
    note_for_driver
    customer_order_number
    delivery_date
    delivery_method
    progress_bar_data {
      isFullTruck
      iMinimumOrderPallet
      iMaximumOrderPallet
      iMinimumOrderWeight
      iMaximumOrderWeight
      iMaximumOrderCash
      sOutletDeliveryMethod
      iTotalCartWeight
      iTotalCartPalletQty
    }
    id
    ship_to_id
    grand_total_your_price_details
    items {
      id
      prices {
        price_details
        multi_deposit_details
        total_item_discount {
          value
        }
        price {
          value
        }
        favourites
        row_total {
          value
        }
        discounts {
          label
          amount {
            value
          }
        }
      }
      product {
        id
        name
        number_of_items_in_box
        catalog_product_name
        sku
        image {
          url
        }
        __typename
      }
      quantity
     number_of_pallet_from_fe
      number_of_layer_from_fe
      quantity_from_fe
      number_of_pallet
      number_of_layer
      quantity_total
      product {
       number_of_products_on_layer
       }
    }
  }
}
`)
