import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e770608"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "information-modal" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-column flex-md-row align-items-center justify-content-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_AsahiTypography, {
            key: 0,
            type: "body-2-bold"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "button",
        class: "btn-close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel())),
        "aria-label": "Close"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.message)
        ? (_openBlock(), _createBlock(_component_AsahiTypography, {
            key: 0,
            type: "body-2",
            class: "d-block mb-3"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.subMessage)
        ? (_openBlock(), _createBlock(_component_AsahiTypography, {
            key: 1,
            type: "body-2-bold",
            class: "d-block mb-3 mt-3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subMessage.split('\n'), (line) => {
                  return (_openBlock(), _createElementBlock("li", { key: line }, _toDisplayString(line), 1))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.cancel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createVNode(_component_AsahiButton, {
              type: "secondary",
              onClick: _cache[1] || (_cache[1] = () => _ctx.onCancel()),
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.cancel), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_AsahiButton, {
              type: "primary",
              onClick: _cache[2] || (_cache[2] = () => _ctx.onConfirm()),
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.confirm), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["d-flex", _ctx.isMobile ? 'w-100 justify-content-center': 'justify-content-end'])
        }, [
          _createVNode(_component_AsahiButton, {
            type: "primary",
            onClick: _cache[3] || (_cache[3] = () => _ctx.onConfirm()),
            class: _normalizeClass(["mt-4", {'w-100': _ctx.isMobile}])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirm), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ], 2))
  ]))
}