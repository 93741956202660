import gql from 'graphql-tag'

export const reOrderSavedOrder = gql`
  query (
    $cart_id: String!,
    $customer_order_number: String,
    $saved_order_type: String,
    $type_of_transport: String,
    $delivery_date: String,
    $ship_to: String
  ) {
    reOrderSavedOrder(
      cart_id: $cart_id,
      customer_order_number: $customer_order_number,
      saved_order_type: $saved_order_type,
      type_of_transport: $type_of_transport,
      delivery_date: $delivery_date,
      ship_to: $ship_to
    ) {
      message
      status
      failed_items {
        sku
        name
        quantity
      }
    }
  }
`
