import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { DocumentActionType } from '@/store/modules/documents/interfaces/action-type'
import { DocumentMutationType } from '@/store/modules/documents/interfaces/mutation-type'
import { IDocumentsState } from '@/store/modules/documents/interfaces/IDocumenstState'
import { IDocumentsMutations } from '@/store/modules/documents/interfaces/IDocumentsMutations'
import { IDocument } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { documentsAPI } from '@/store/modules/documents/api/documents-api'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { IBulkDocumentsRequest } from '@/store/modules/documents/interfaces/api/BulkDocuments/IBulkDocumentsRequest'
import { IDocumentsActions } from '@/store/modules/documents/interfaces/IDocumentsActions'
import { IDropdownItems } from '@/store/modules/common/interface/IDropdownItems'
import { IBulkDownload } from '@/store/modules/documents/interfaces/IBulkDocument'

const state = (): IDocumentsState => ({
  documents: [],
  documentTypes: [],
  bulkDownload: {} as Blob,
  total: 0,
  loading: false,
  errors: null,
  fileType: 'PDF'
})

const mutations: MutationTree<IDocumentsState> & IDocumentsMutations = {
  [DocumentMutationType.SET_LOADING] (state: IDocumentsState, payload: boolean) {
    state.loading = payload
  },
  [DocumentMutationType.SET_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.documents = payload
  },
  [DocumentMutationType.RESET_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.documents = payload
  },
  [DocumentMutationType.SET_TOTAL] (state: IDocumentsState, payload: number) {
    state.total = payload
  },
  [DocumentMutationType.SET_ERROR] (state: IDocumentsState, payload: any) {
    state.errors = payload
  },
  [DocumentMutationType.SET_DOCUMENT_TYPE] (state: IDocumentsState, payload: IDropdownItems[]) {
    state.documentTypes = payload
  },
  [DocumentMutationType.SET_BULK_DOCUMENTS] (state: IDocumentsState, payload: Blob) {
    state.bulkDownload = payload
  },
  [DocumentMutationType.SET_FILE_TYPE] (state: IDocumentsState, payload: string) {
    state.fileType = payload
  }
}

const actions: ActionTree<IDocumentsState, IRootState> & IDocumentsActions = {
  async [DocumentActionType.FETCH_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const { reset = false, ...restDto } = { ...dto }

      const response = await documentsAPI.getDocuments(restDto) // Use await directly for async operation

      if (reset) {
        commit(DocumentMutationType.RESET_DOCUMENTS, [])
      }

      // Handle document filtering or setting based on conditions
      let documents = response.documentList

      if (response.documentList?.length > 1) {
        const lfmt = response.documentList.find(
          (document) =>
            document?.link?.includes('lfmt') &&
            (document?.documentType?.includes('Invoice') || document?.documentType?.includes('Faktura'))
        )
        if (lfmt) {
          documents = response.documentList.filter(
            (document) =>
              document?.link?.includes('lfmt') ||
              document?.documentType?.includes('Invoice') ||
              document?.documentType?.includes('Faktura')
          )
        }
      }

      commit(DocumentMutationType.SET_DOCUMENTS, documents)
      commit(DocumentMutationType.SET_TOTAL, response.total)
      commit(DocumentMutationType.SET_LOADING, false)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },

  async [DocumentActionType.FETCH_DOCUMENT_TYPES] ({ commit }) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getDocumentTypes() // Await for async operation

      let results: IDropdownItems[] = []
      if (Array.isArray(response) && response.length > 0) {
        results = response.map((d) => ({
          label: d.value,
          value: d.value
        }))
      }

      commit(DocumentMutationType.SET_DOCUMENT_TYPE, results)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
    }
    commit(DocumentMutationType.SET_LOADING, false)
  },
  async [DocumentActionType.FETCH_BULK_DOCUMENTS] ({ commit }, dto: IBulkDownload[]) { // Adjust parameter type to IBulkDownload[]
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const data = await documentsAPI.fetchBulkDocuments(dto)
      if (data) {
        commit(DocumentMutationType.SET_BULK_DOCUMENTS, data)
      }
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
    } finally {
      commit(DocumentMutationType.SET_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
