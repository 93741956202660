<template>
  <div v-for="option in options" :key="option.value" :class="{ 'HtmlRadio': (type === 'HtmlRadio'), 'white-bg': isWhite, 'checked': option.value == selectedValue, 'no-border': noBorder, isInModal}">
    <label class="asahi-radio-button-container">
      <div>
        <input
          :name="name"
          type="radio"
          :value="option.value"
          :checked="isSelected(option.value)"
          @change="selectedRadioOption">
        <span class="checkmark"></span>
      </div>
      <div class="label-container" :class="{ 'text-left': textAlign === 'left'}">
        <span class="option-title">{{option.label}}</span>
        <span class="option-description" v-if="option.description">{{ option.description }}</span>
      </div>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AsahiRadioButton',
  props: {
    options: Array,
    selectedValue: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    isInModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['chooseOption'],
  methods: {
    isSelected (value) {
      return this.selectedValue === value
    },
    selectedRadioOption (event) {
      this.$emit('chooseOption', { label: this.label, value: event.target.value })
    }
  }
})
</script>

<style scoped lang='scss'>

/* The container */
.asahi-radio-button-container {
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  white-space: nowrap;
  height: fit-content;
  @media(max-width: $breakpoint-sm) {
    white-space: pre-wrap;
  }
}
input[type='radio'] {
  -webkit-appearance:none;
  appearance: none;
}
/* Hide the browser's default radio button */
.asahi-radio-button-container input {
  appearance: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid $radio-checkmark-color;
}

/* On mouse-over, add a grey background color */
.asahi-radio-button-container:hover input ~ .checkmark {
  background-color: transparent;
  border: 2px solid $border-grey;
}

/* When the radio button is checked, add a blue background */
.asahi-radio-button-container input:checked ~ .checkmark {
  background-color:  $radio-button-checekd-bg-color;
  border: 2px solid $radio-button-checekd-border-color;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.asahi-radio-button-container input:checked ~ .checkmark:after {
  display: block;
  background-color: $radio-button-checekd-border-color;
}

/* Style the indicator (dot/circle) */
.asahi-radio-button-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $radio-checkmark-color;
}

.HtmlRadio {
  .asahi-radio-button-container {
    color: $secondary-black;
  }
  .asahi-radio-button-container input {
    border: 1px solid rgba($html-radio-button-checekd-border-color, 0.2)
  }

  .checkmark {
    background-color: $primary-white;
    border-radius: 50%;
    border: 1px solid $html-radio-button-checekd-border-color;
  }
  .asahi-radio-button-container:hover input ~ .checkmark {
    background-color: transparent;
    border: 1px solid $html-radio-checkmark-color;
  }
  .asahi-radio-button-container input:checked ~ .checkmark {
    background-color: $primary-white;
    border: 1px solid $radio-button-checekd-border-color;
  }
  .asahi-radio-button-container input:checked ~ .checkmark:after {
    display: block;
    background-color: $radio-button-checekd-border-color;
  }

  .asahi-radio-button-container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 12px;
    height: 11px;
    background: $html-radio-checkmark-color;
  }
}

.white-bg {
  .checkmark {
    background-color: transparent;
    border: 2px solid $border-grey;
  }
  .asahi-radio-button-container {
    color: $primary-black;
  }
  .asahi-radio-button-container input:checked ~ .checkmark {
    background-color: $primary-white;
    border: 2px solid $radio-button-checekd-border-color;
  }
}
.white-bg.checked {
  .checkmark {
    border: 2px solid $primary-gold;
  }

  .option-title {
    font-weight: bold;
    @media(max-width: $breakpoint-sm) {
      text-wrap: initial;
    }
  }
}

.text-left {
  text-align: left;
}

.label-container {
  display: flex;
  flex-direction: column;
}

.option-description {
  font-size: 14px;
  @media(max-width: $breakpoint-sm) {
    text-wrap: initial;
  }
}

/* No Border Class */
.no-border {
  .checkmark {
    border: 1px solid $mercury-grey;
  }
  .asahi-radio-button-container input:checked ~ .checkmark {
    border: 1px solid $mercury-grey;
  }
  .asahi-radio-button-container input:checked ~ .checkmark:after {
    display: block;
    background-color: $radio-button-checekd-border-color;

  }
}

.isInModal{
  .label-container{
    border: none;
    height: auto;
    padding: 0;
  }

  .asahi-radio-button-container:hover input ~ .checkmark {
    background-color: transparent;
    border: 2px solid $primary-gold;
  }

  .checkmark{
    border-color: $border-grey;
  }

}

.isInModal.checked .checkmark.checkmark{
  background-color: transparent;
}
</style>
