export enum BasketMutationTypes {
  'ADD_SUGGESTED_BASKET' = 'ADD_SUGGESTED_BASKET',
  'RESET_SUGGESTED_BASKETS' = 'RESET_SUGGESTED_BASKETS',
  'SET_EMPTY_CART' = 'SET_EMPTY_CART',
  'SET_CART_QUERY' = 'SET_CART_QUERY',
  'SET_PRODUCTS' = 'SET_PRODUCTS',
  'SET_TOTAL_PRICE' = 'SET_TOTAL_PRICE',
  'SET_LOADING' = 'SET_LOADING',
  'SET_ERROR' = 'SET_ERROR',
  'SET_BASKET_ID' = 'SET_BASKET_ID',
  'SET_BASKET_ITEMS' = 'SET_BASKET_ITEMS',
  'SET_BASKET_PRODUCTS' = 'SET_BASKET_PRODUCTS',
  'SET_SHIPPING_ADDRESS' = 'SET_SHIPPING_ADDRESS',
  'SET_SHIPPING_METHODS' = 'SET_SHIPPING_METHODS',
  'PAYMENT_METHOD' = 'PAYMENT_METHOD',
  'SET_PRICES' = 'SET_PRICES',
  'SET_ORDER' = 'SET_ORDER',
  'SET_PROGRESS_BAR' = 'SET_PROGRESS_BAR',
  'SET_DELIVERY_DATE' = 'SET_DELIVERY_DATE',
  'SET_SHIPMENT_ID' = 'SET_SHIPMENT_ID',
  'SET_TRANSPORT_TYPE' = 'SET_TRANSPORT_TYPE',
  'SET_FIRST_AVAILABLE_DATE' = 'SET_FIRST_AVAILABLE_DATE',
  'SET_CUSTOMER_SETTINGS' = 'SET_CUSTOMER_SETTINGS',
  'SET_SUGGESTED_CART' = 'SET_SUGGESTED_CART',
  'SET_EMPTIES' = 'SET_EMPTIES',
  'SET_UPDATED_EMPTIES' = 'SET_UPDATED_EMPTIES',
  'SET_DEFAULT_EMPTIES' = 'SET_DEFAULT_EMPTIES',
  'SET_SEPARATED_ORDER' = 'SET_SEPARATED_ORDER',
  'SET_TOAST_NOTIFICATION_DETAILS' = 'SET_TOAST_NOTIFICATION_DETAILS',
  'SET_AVAILABLE_DAYS' = 'SET_AVAILABLE_DAYS',
  'SET_SUGGESTED_BASKET' = 'SET_SUGGESTED_BASKET',
  'SET_FILTERED_AVAILABLE_DAYS' = 'SET_FILTERED_AVAILABLE_DAYS'
}
