export enum DocumentMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  RESET_DOCUMENTS = 'RESET_DOCUMENTS',
  SET_ERROR = 'SET_ERROR',
  SET_TOTAL = 'SET_TOTAL',
  SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE',
  SET_BULK_DOCUMENTS = 'SET_BULK_DOCUMENTS',
  SET_FILE_TYPE= 'SET_FILE_TYPE'
}
