import { IHelpPages } from '@/store/modules/help-pages/interfaces'

function replacer (key: string, value: any) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries())
    }
  } else {
    return value
  }
}

function reviver (key: string, value: any) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value)
    }
  }
  return value
}

interface ISeenHelpPage {
    id: string;
    date: string;
}

export const getUnseenHelpPages = (helppages: Array<IHelpPages>, userId: string) => {
  const localStorageTutorials = localStorage.getItem('sawTutorials')

  let myTutorials: ISeenHelpPage[] = []
  let sawTutorials: Map<string, Array<ISeenHelpPage>> = new Map<string, Array<ISeenHelpPage>>()

  if ((localStorageTutorials !== 'null') && localStorageTutorials != null) {
    sawTutorials = JSON.parse(localStorageTutorials, reviver)
    const a = sawTutorials.get(userId)
    if (a) myTutorials = a
  }

  const datesMap = new Map<string, number>(myTutorials?.map((item: ISeenHelpPage) => [item.id, new Date(item.date).getTime()]))

  return helppages.filter(item => {
    const date = datesMap.get(item.id)
    return !date || (new Date(item.mgnllastModified) > new Date(date))
  })
}

export const addSeenHelpPages = (helppages: Array<IHelpPages>, userId: string) => {
  const localStorageTutorials = localStorage.getItem('sawTutorials')

  let sawTutorials: Map<string, Array<ISeenHelpPage>> = new Map<string, Array<ISeenHelpPage>>()
  let arr: ISeenHelpPage[] = []

  if ((localStorageTutorials !== 'null') && localStorageTutorials != null) {
    sawTutorials = JSON.parse(localStorageTutorials, reviver)
    const a = sawTutorials.get(userId)
    if (a) arr = a
  }

  const newIds = helppages.map(h => {
    return {
      id: h.id,
      date: new Date().toString()
    }
  })

  arr = [...new Set([...arr, ...newIds])]

  sawTutorials.set(userId, arr)

  localStorage.setItem('sawTutorials', JSON.stringify(sawTutorials, replacer))
}
