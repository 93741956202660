<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.27477 13.3801L11.3461 20.355C11.6308 20.6358 11.7731 20.7762 11.9465 20.7948C11.9821 20.7987 12.0179 20.7987 12.0535 20.7948C12.2269 20.7762 12.3692 20.6358 12.6539 20.355L19.7252 13.3801C21.6044 11.5266 21.8351 8.57345 20.2665 6.45061L19.8269 5.85576C17.8864 3.22962 13.8383 3.65571 12.4871 6.62832C12.297 7.04655 11.703 7.04655 11.5129 6.62832C10.1617 3.65571 6.11358 3.22962 4.17309 5.85576L3.73354 6.45061C2.16494 8.57344 2.3956 11.5266 4.27477 13.3801Z" stroke="#9f8852" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'AsahiFavoriteIcon'
}

</script>
<style lang='scss'>

</style>
