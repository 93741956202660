import gql from 'graphql-tag'

export const addSaveOrderForLaterQuery = gql`
  query ($cartId: String!, $noteForDriver: String, $customerOrderNumber: String) {
    addSaveOrderForLater(
      cart_id: $cartId,
      note_for_driver: $noteForDriver,
      customer_order_number: $customerOrderNumber
    ) {
      message
    }
  }
`
