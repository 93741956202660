import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "custom-day-week" }
const _hoisted_3 = { class: "icons" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "custom-month-year-component" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "d-flex justify-content-start" }
const _hoisted_10 = { class: "delivery-legends" }
const _hoisted_11 = { class: "non-delivery-legends" }
const _hoisted_12 = {
  key: 0,
  class: "row p-2"
}
const _hoisted_13 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    modelValue: _ctx.deliveryDate,
    "onUpdate:modelValue": [
      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deliveryDate) = $event)),
      _ctx.onDateSelection
    ],
    ref: "vueDatePickerRef",
    "auto-apply": !_ctx.isMobile,
    "action-row": { showNow: false, showPreview: false },
    "enable-time-picker": false,
    "month-change-on-scroll": false,
    config: {keepActionRow: false, onClickOutside: false},
    "hide-offset-dates": true,
    "allowed-dates": _ctx.availableDates,
    locale: _ctx.localeValue,
    clearable: false,
    "min-date": _ctx.firstAvailableDate,
    "month-name-format": "long",
    format: "dd.MM.yyyy",
    "calendar-class-name": "custom-calendar",
    "menu-class-name": "custom-menu",
    "input-class-name": _ctx.inputClassName,
    "day-class": _ctx.getDayClass,
    markers: _ctx.markers,
    onFocus: _ctx.onCalendarOpen,
    onOpen: _ctx.onCalendarOpen,
    onClosed: _ctx.onCalendarClose,
    onBlur: _ctx.onCalendarClose,
    "teleport-center": _ctx.isMobile,
    class: _normalizeClass([_ctx.isCompact ? 'invert-filter' : ''])
  }, {
    "input-icon": _withCtx(() => [
      (!_ctx.isCompact)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.CalendarIcon
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    "calendar-header": _withCtx(({ index }) => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dayOfWeek[index]), 1)
    ]),
    "month-year": _withCtx(({
        month,
        year,
        months,
        handleMonthYearChange
    }) => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: "custom-icon",
          onClick: ($event: any) => (handleMonthYearChange(false))
        }, [
          _createElementVNode("img", { src: _ctx.ChevronLeftIcon }, null, 8, _hoisted_5)
        ], 8, _hoisted_4),
        _createElementVNode("div", _hoisted_6, _toDisplayString(months !== undefined && month !== undefined ? months[month].text : '') + " " + _toDisplayString(year), 1),
        _createElementVNode("span", {
          class: "custom-icon",
          onClick: ($event: any) => (handleMonthYearChange(true))
        }, [
          _createElementVNode("img", { src: _ctx.ChevronRightIcon }, null, 8, _hoisted_8)
        ], 8, _hoisted_7)
      ])
    ]),
    "action-extra": _withCtx(() => [
      _createElementVNode("ul", _hoisted_9, [
        _createElementVNode("li", _hoisted_10, [
          _createVNode(_component_AsahiTypography, {
            type: "subtitle-1",
            color: "primary-black"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Delivery days')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_11, [
          _createVNode(_component_AsahiTypography, {
            type: "subtitle-1",
            color: "primary-black"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Non-delivery days')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_AsahiButton, {
                type: "primary",
                class: "w-100",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveChanges()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Select")), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    "action-buttons": _withCtx(() => []),
    _: 1
  }, 8, ["modelValue", "auto-apply", "allowed-dates", "locale", "min-date", "input-class-name", "day-class", "markers", "onFocus", "onOpen", "onClosed", "onBlur", "onUpdate:modelValue", "teleport-center", "class"]))
}