import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4559f8a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "select-label"
}
const _hoisted_2 = { class: "d-flex justify-content-between gap-3 align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "select-icon"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "first-line" }
const _hoisted_7 = { class: "second-line" }
const _hoisted_8 = ["title"]
const _hoisted_9 = {
  key: 0,
  class: "triangle-up"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiArrow = _resolveComponent("AsahiArrow")!
  const _component_AsahiRadioButton = _resolveComponent("AsahiRadioButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select-box", [_ctx.color, {isInModal: _ctx.isInModal, openDropdown: _ctx.openDropdown}]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({'select-container': _ctx.isTicket, 'ticket-container mb-3': !_ctx.isTicket})
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["selected-option d-flex justify-content-between w-100", { 'invalid': !_ctx.isValid, 'valid': _ctx.isValid, 'no-border': _ctx.noBorder }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.icon)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.icon,
                      alt: "icon",
                      class: "option-icon"
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.secondLine)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["second-line-wrapper", {'selected-text': (_ctx.isTicket && !_ctx.fullWidth), 'ticket-label': !_ctx.isTicket, 'text-full-width' : _ctx.fullWidth}]),
                title: _ctx.displayLabel
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.displayLabel || _ctx.defaultLabel), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.secondLine), 1)
              ], 10, _hoisted_5))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass({'selected-text': (_ctx.isTicket && !_ctx.fullWidth), 'ticket-label': !_ctx.isTicket, 'text-full-width' : _ctx.fullWidth}),
                title: _ctx.displayLabel
              }, _toDisplayString(_ctx.displayLabel || _ctx.defaultLabel), 11, _hoisted_8))
        ]),
        _createVNode(_component_AsahiArrow, {
          class: "arrow-container",
          "is-rotated": !_ctx.openDropdown,
          arrowColor: _ctx.color === 'gold' ? '#9f8852' : 'black'
        }, null, 8, ["is-rotated", "arrowColor"])
      ], 2),
      (_ctx.openDropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9))
        : _createCommentVNode("", true),
      (_ctx.openDropdown)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["options-container py-3", { 'max-content': _ctx.optionsWrap, 'w-100': _ctx.hFillOption }])
          }, [
            _createVNode(_component_AsahiRadioButton, {
              name: _ctx.name,
              selectedValue: _ctx.modelValue,
              options: _ctx.filteredOptions,
              isInModal: _ctx.isInModal,
              onChooseOption: _ctx.selectOption
            }, null, 8, ["name", "selectedValue", "options", "isInModal", "onChooseOption"])
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}