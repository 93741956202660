export enum OrderMutationType {
  SET_ORDER_LIST = 'SET_ORDER_LIST',
  SET_TOTAL_ORDERS = 'SET_TOTAL_ORDERS',
  SET_SAVED_ORDERS = 'SET_SAVED_ORDERS',
  SET_SAVED_ORDER_DETAILS = 'SET_SAVED_ORDER_DETAILS',
  SET_TOTAL_SAVED_ORDERS = 'SET_TOTAL_SAVED_ORDERS',
  SET_LOADING = 'SET_LOADING',
  SET_REORDER_SAVED_ORDER = 'SET_REORDER_SAVED_ORDER',
  SET_DELETE_SAVED_ORDER = 'SET_DELETE_SAVED_ORDER',
  SET_REORDER_HISTORY_ORDER = 'SET_REORDER_HISTORY_ORDER',
  SET_CANCEL_SAVED_ORDER = 'SET_CANCEL_SAVED_ORDER',
  SET_SAVED_ORDER_PAGE_INFO = 'SET_SAVED_ORDER_PAGE_INFO',
  FETCH_SUBMITTED_ORDER_DETAILS = 'FETCH_SUBMITTED_ORDER_DETAILS',
  SET_SUBMITTED_ORDER = 'SET_SUBMITTED_ORDER',
  SAVE_CUSTOMER_ORDER_NUMBER = 'SAVE_CUSTOMER_ORDER_NUMBER',
  SET_CUSTOMER_ORDER_NUMBER = 'SET_CUSTOMER_ORDER_NUMBER'
}
