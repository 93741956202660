import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-180ac832"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "d-flex justify-content-end" }
const _hoisted_4 = { class: "modal-header mt-3" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer row" }
const _hoisted_8 = { class: "col-8 col-sm-4 col-md-3 col-lg-5" }
const _hoisted_9 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiTypography = _resolveComponent("AsahiTypography")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "modal-container align-items-center mb-3",
        style: _normalizeStyle({ width: _ctx.width, height: _ctx.height })
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
            "aria-label": "Close"
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "header", {}, () => [
            _createVNode(_component_AsahiTypography, { type: "title-1-bold" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.message)), 1)
              ]),
              _: 1
            })
          ], true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_AsahiTypography, { type: "title-2-regular" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.headerSubMsg)), 1)
            ]),
            _: 1
          }),
          (_ctx.hyperLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.hyperLink,
                target: "_self",
                color: "tertiary"
              }, _toDisplayString(_ctx.hyperLinkText), 9, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "body", {}, () => [
            _createVNode(_component_AsahiTypography, { type: "subtitle" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.subMessage)), 1)
              ]),
              _: 1
            })
          ], true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer", {}, () => [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.showButton)
                ? (_openBlock(), _createBlock(_component_AsahiButton, {
                    key: 0,
                    type: "primary",
                    class: "w-100",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(_ctx.buttonText)), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_AsahiTypography, { type: "subtitle" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(_ctx.footerMessage)), 1)
                ]),
                _: 1
              })
            ])
          ], true)
        ])
      ], 4)
    ])
  ]))
}