import { generalServiceV1API } from '@/store/api-instance'
import { IGetAvailableDateRequest, IGetDeliveryAvailableDateRequest } from '../interfaces/getAvailableDates/IGetAvailableDateRequest'
import { AxiosResponse } from 'axios'

async function getAvailableDateAPI (queryParams: IGetAvailableDateRequest) {
  const response: AxiosResponse = await generalServiceV1API.get('orders/GetFirstAvailableDate', { params: queryParams })
  return response.data
}

async function getCalendarAvailableDaysAPI (queryParams: IGetAvailableDateRequest) {
  const response: AxiosResponse = await generalServiceV1API.get('orders/GetCalendarAvailableDays', { params: queryParams })
  return response.data
}

async function getDeliveryAvailableDateAPI (queryParams: IGetDeliveryAvailableDateRequest) {
  const response: AxiosResponse = await generalServiceV1API.get('orders/GetDeliveryAvailableDate', { params: queryParams })
  return response.data
}
export {
  getAvailableDateAPI,
  getCalendarAvailableDaysAPI,
  getDeliveryAvailableDateAPI
}
