<template>
  <div class='loading-container my-4'>
    <div v-for='index in itemCount' :key='index' class='card-container gap-3 d-flex flex-column'>
      <div class='card' :class="{'active': activeIndex === index}">
      </div>
      <div class='label' :class="{'active': activeIndex === index}"></div>
    </div>
  </div>
  <div class='loading-button my-5'></div>

</template>

<script>
export default {
  name: 'AsahiLoaderCard',
  data () {
    return {
      activeIndex: 1
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth <= 768
    },
    itemCount () {
      return this.isMobile ? 2 : 4
    }
  },
  mounted () {
    this.startAnimation()
  },
  methods: {
    startAnimation () {
      setInterval(() => {
        this.activeIndex = this.activeIndex % 4 + 1
      }, 500)
    }
  }
}
</script>

<style scoped lang='scss'>
.loading-container {
  display: flex;
  justify-content: space-evenly;
  max-width: 1320px;
  margin: auto;
}

.card {
  width: 146px;
  height: 146px;
  background-color: $mercury-grey;
  transition: background-color 0.3s;
}

.card.active {
  background-color: #eeeeee;
}

.label {
  width: 146px;
  height: 22px;
  border-radius: 50px;
  background-color: $mercury-grey;
  transition: background-color 0.3s;
}

.label.active {
  background-color:  #eeeeee;
}
.loading-button {
  background-color: $mercury-grey;
  width: 328px;
  height: 42px;
  margin: auto;
  border-radius: 50px;

}
</style>
