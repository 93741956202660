import gql from 'graphql-tag'

export const fetchProductsAggregationsQuery = gql`
  query ($sku: [String] , $brand: [String] ) {
    products(filter: { sku: { in: $sku } , brand : { in: $brand}}) {
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
    }
  }
`
