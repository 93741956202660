import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../library/assets/Arrow.png'
import _imports_1 from '../library/assets/Arrow-open.png'
import _imports_2 from '../library/assets/language-selected.png'


const _withScopeId = n => (_pushScopeId("data-v-72e1e54b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "language-picker-container" }
const _hoisted_2 = { src: _imports_0 }
const _hoisted_3 = {
  class: "asahi-icon-rotated-white",
  src: _imports_1
}
const _hoisted_4 = {
  key: 0,
  class: "language-picker-label"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "asahi-language" }
const _hoisted_7 = {
  key: 0,
  class: "language-selected-icon",
  src: _imports_2
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openLanguageDropdown && _ctx.openLanguageDropdown(...args))),
      class: _normalizeClass(["language-picker", { 'asahi-language-open': _ctx.openLanguage }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.getFullLanguageName(_ctx.activeLanguage)) + " ", 1),
      _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
        [_vShow, !_ctx.openLanguage]
      ]),
      _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
        [_vShow, _ctx.openLanguage]
      ]),
      (_ctx.openLanguage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (lang) => {
              return (_openBlock(), _createElementBlock("div", {
                key: lang,
                onClick: ($event: any) => (_ctx.changeLanguage(lang)),
                class: "clickable"
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", {
                    class: _normalizeClass(["asahi-lang-label", { 'selected-language': _ctx.activeLanguage === lang }])
                  }, _toDisplayString(_ctx.getFullLanguageName(lang)), 3),
                  (_ctx.activeLanguage === lang)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ])), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}