<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8" r="6" fill="#388F27" stroke="#388F27" stroke-width="2"  v-if="!isWhite"/>
    <path d="M5.83464 8L7.83464 10L11.168 6" stroke="white" stroke-width="2"  v-if="!isWhite"/>
    <circle cx="8.5" cy="8" r="6" fill="white" stroke="white" stroke-width="2"  v-if="isWhite"/>
    <path d="M5.83464 8L7.83464 10L11.168 6" stroke="#388F27" stroke-width="2"  v-if="isWhite"/>
  </svg>

</template>

<script>
export default {
  name: 'SuccessCart',

  props: {
    isWhite: Boolean,
    heightValue: Number,
    widthValue: Number
  }
}

</script>
<style lang='scss'>
</style>
