
import { defineComponent, PropType } from 'vue'
import AsahiPopup from '@/components/library/AsahiPopUp.vue'

export default defineComponent({
  name: 'AsahiCustomButton',
  components: { AsahiPopup },
  data: () => ({
    openDropdown: false,
    input: ''
  }),
  props: {
    dropdownName: String,
    options: {
      type: Array as PropType<Array<{ href: string }>>,
      default: () => []
    },
    popUpType: {
      type: String,
      default: 'pop-up'
    },
    iconQuantity: Number,
    selected: Boolean,
    isPadding: Boolean
  },
  methods: {
    toggleDropdown () {
      this.openDropdown = !this.openDropdown
    }
  }
})
