import { AxiosResponse } from 'axios'
import { IFetchPageContentRequest, IFetchPageContentResponse, IPageContents, IPageContentsActions, IPageContentsMutations, IPageContentsState, PageContentsActionType, PageContentsMutationType } from './interfaces'
import { magnoliaServiceAPI } from '@/store/api-instance'
import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'

const state = (): IPageContentsState => ({
  pageContents: [],
  loading: false
})

const fetchPageContents = async (dto: IFetchPageContentRequest): Promise<IFetchPageContentResponse> => {
  const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchPageContentResponse>('pagecontent', { params: dto })
  return response.data
}

const mutations: MutationTree<IPageContentsState> & IPageContentsMutations = {
  [PageContentsMutationType.SET_LOADING] (state: IPageContentsState, payload: boolean) {
    state.loading = payload
  },
  async [PageContentsMutationType.SET_ALL_PAGE_CONTENTS] (state: IPageContentsState, payload: Array<IPageContents>) {
    state.pageContents = payload
  },
  async [PageContentsMutationType.ADD_PAGE_CONTENTS] (state: IPageContentsState, payload: Array<IPageContents>) {
    state.pageContents = [...new Set([...state.pageContents, ...payload])]
  }
}

const actions: ActionTree<IPageContentsState, IRootState> & IPageContentsActions = {
  async [PageContentsActionType.FETCH_PAGE_CONTENTS] ({ commit }, dto: IFetchPageContentRequest) {
    commit(PageContentsMutationType.SET_LOADING, true)
    const data = await fetchPageContents(dto)
    commit(PageContentsMutationType.ADD_PAGE_CONTENTS, data?.results)
    commit(PageContentsMutationType.SET_LOADING, false)
  }
}

export default {
  state,
  mutations,
  actions
}
