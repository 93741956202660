import gql from 'graphql-tag'

export const fetchSubmittedOrderDetailsQuery = gql`
  query ($orderNumber: [String]) {
    customer {
      orders(filter: { number: { in: $orderNumber } }) {
        items {
          order_number
          id
          created_at
          grand_total
          status
          items {
            product_sku
            quantity_ordered
          }

          additional_data {
            delivery_date
            delivery_message
            empty_return
            ship_to_name
            total_empties_deposit
            total_your_price
            customer_order_number
            payment_method
            total_your_price_tax
            total_your_price_empty_deposit
            type_of_transport
          }

          shipping_address {
            firstname
            lastname
            middlename
            region
            region_id
            country_code
            street
            company
            telephone
            fax
            postcode
            city
            prefix
            suffix
            vat_id
          }
          billing_address {
            firstname
            lastname
            middlename
            region
            region_id
            country_code
          }
        }
      }
    }
  }
`
