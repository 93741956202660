
import { defineComponent } from 'vue'
import { setI18nLanguage } from '@/i18n'
import { LanguagesType } from '@/types'

export default defineComponent({
  name: 'AsahiLanguageSelector',
  emits: ['languagechange'],
  props: {
    languages: {
      type: Array as () => string[],
      required: true
    },
    activeLanguage: {
      type: String,
      required: true
    }
  },
  data: () => ({
    openLanguage: false
  }),

  methods: {
    handleClickOutside () {
      this.openLanguage = false
    },
    openLanguageDropdown () {
      this.openLanguage = !this.openLanguage
    },
    changeLanguage (langCode: LanguagesType) {
      if (langCode !== this.activeLanguage) {
        setI18nLanguage(langCode)
        this.$emit('languagechange', langCode)
      }
    },
    getFullLanguageName (code: string): string {
      const languageMap: { [key: string]: string } = { en: 'English', cs: 'Česky', sk: 'Slovensky' }
      return languageMap[code] || code
    }
  }
})
