import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3565fd9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "asahi-icon-quantity"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AsahiPopup = _resolveComponent("AsahiPopup")!

  return (_openBlock(), _createElementBlock("div", {
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDropdown = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDropdown = false)),
    class: _normalizeClass(["asahi-button-container", {'asahi-dropdown-opened': (_ctx.openDropdown || _ctx.selected)}])
  }, [
    (_ctx.iconQuantity > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.iconQuantity), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_router_link, {
      to: _ctx.options.length > 0 ? _ctx.options[0].href : '#'
    }, {
      default: _withCtx(() => [
        (!_ctx.openDropdown)
          ? _renderSlot(_ctx.$slots, "icon", {
              key: 0,
              class: "asahi-header-icon"
            }, () => [
              _createTextVNode(_toDisplayString(_ctx.options), 1)
            ], true)
          : _createCommentVNode("", true),
        ((_ctx.openDropdown))
          ? _renderSlot(_ctx.$slots, "hover-icon", {
              key: 1,
              class: "asahi-header-icon-hover"
            }, undefined, true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["to"]),
    (_ctx.popUpType === 'pop-up')
      ? (_openBlock(), _createBlock(_component_AsahiPopup, {
          key: 1,
          open: _ctx.openDropdown,
          options: _ctx.options,
          isPadding: _ctx.isPadding,
          "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDropdown = $event))
        }, null, 8, ["open", "options", "isPadding"]))
      : _createCommentVNode("", true)
  ], 34))
}