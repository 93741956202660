import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../library/assets/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-1cd90874"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  id: "header ",
  class: "min-w-300"
}
const _hoisted_3 = { class: "row w-auto" }
const _hoisted_4 = { class: "col-1 d-flex align-items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-9 d-flex align-items-center" }
const _hoisted_7 = { class: "col-2 d-flex align-items-center justify-content-end" }
const _hoisted_8 = { class: "row w-100" }
const _hoisted_9 = { class: "col-10 text-left" }
const _hoisted_10 = { class: "fw-bold text-black" }
const _hoisted_11 = { class: "row w-100" }
const _hoisted_12 = { class: "col-10 text-left" }
const _hoisted_13 = { class: "text-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asahi_typography = _resolveComponent("asahi-typography")!

  return (_openBlock(), _createElementBlock("div", {
    class: "basic-modal-wrap",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
  }, [
    _createElementVNode("div", {
      class: "basic-modal bg-white p-3 m-4",
      onClick: _withModifiers(() => { }, ["stop"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              alt: "icon",
              class: "asahi-address-icon black-color-png",
              src: _ctx.icon
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_asahi_typography, {
              type: "headline-1",
              color: "primary-black"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title?.toUpperCase()), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              alt: "icon",
              class: "asahi-address-icon",
              onClick: _cache[0] || (_cache[0] = () => _ctx.closeModal()),
              src: _imports_0
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.subtitle), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.description), 1)
          ])
        ])
      ])
    ], 8, _hoisted_1)
  ]))
}