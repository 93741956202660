<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9 14L0.5 12.6L6.1 7L0.5 1.4L1.9 0L7.5 5.6L13.1 0L14.5 1.4L8.9 7L14.5 12.6L13.1 14L7.5 8.4L1.9 14Z" fill="#1C1B1F"/>
  </svg>
</template>

<script>
export default {
  name: 'AsahiClose'
}

</script>
<style lang='scss'>

</style>
