import { AxiosResponse } from 'axios'
import { generalServiceV1API } from '@/store/api-instance'
import { IInvoicesOlsaRequest } from '@/store/modules/invoicesOlsa/interfaces/invoicesOlsa/IInvoicesOlsaRequest'
import { IInvoicesOlsaResponse } from '@/store/modules/invoicesOlsa/interfaces/invoicesOlsa/IInvoicesOlsaResponse'
import { ISelectedInvoiceOlsaRequest } from '../interfaces/selectedInvoiceOlsa/ISelectedInvoiceOlsaRequest'
import { ISelectedInvoiceOlsaResponse } from '../interfaces/selectedInvoiceOlsa/ISelectedInvoiceOlsaResponse'

export const invoicesOlsaAPI = {
  async getInvoicesOlsa (dto: IInvoicesOlsaRequest): Promise<IInvoicesOlsaResponse> {
    const response: AxiosResponse<IInvoicesOlsaResponse> = await generalServiceV1API.post(
      'invoiceolsa/getdatarchive',
      dto
    )
    return response.data
  },
  async getSelectedInvoiceOlsa (dto: ISelectedInvoiceOlsaRequest): Promise<ISelectedInvoiceOlsaResponse> {
    const response: AxiosResponse<ISelectedInvoiceOlsaResponse> = await generalServiceV1API.post(
      'invoiceolsa/getdocument',
      dto
    )
    return response.data
  }
}
