import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../library/assets/Arrow.png'
import _imports_1 from '../library/assets/Arrow-open.png'


const _withScopeId = n => (_pushScopeId("data-v-84191ff4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { src: _imports_0 }
const _hoisted_3 = {
  class: "asahi-icon-rotated-white",
  src: _imports_1
}
const _hoisted_4 = {
  class: "asahi-icon-rotated-gold",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiPopup = _resolveComponent("AsahiPopup")!

  return (_ctx.options.length >= 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "asahi-dropdown-container create-order",
        onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDropdown = false))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["asahi-dropdown-title", {'asahi-dropdown-close': !_ctx.openDropdown, 'asahi-dropdown-open' : _ctx.openDropdown }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTitleClick && _ctx.handleTitleClick(...args))),
          style: _normalizeStyle({ backgroundColor: _ctx.typeColor }),
          onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDropdown = true))
        }, [
          (_ctx.type === 'text')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["asahi-dropdown-name", {' asahi-dropdown-underline': _ctx.openDropdown }]),
                style: _normalizeStyle({ color: _ctx.textColor, border: _ctx.borderType })
              }, _toDisplayString(_ctx.dropdownName), 7))
            : (_ctx.type === 'image')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.dropdownName,
                  alt: _ctx.dropdownName,
                  class: "asahi-dropdown-image"
                }, null, 8, _hoisted_1))
              : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({'asahi-dropdown-icon': _ctx.openDropdown})
          }, [
            _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
              [_vShow, !_ctx.openDropdown]
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
              [_vShow, _ctx.openDropdown && _ctx.iconType === 'white']
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
              [_vShow, _ctx.openDropdown && _ctx.iconType === 'gold']
            ])
          ], 2)
        ], 38),
        _createVNode(_component_AsahiPopup, {
          open: _ctx.openDropdown,
          options: _ctx.options,
          "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDropdown = $event))
        }, null, 8, ["open", "options"])
      ], 32))
    : _createCommentVNode("", true)
}