
import { defineComponent } from 'vue'

import facebook from '../../assets/facebook.svg'
import linkedin from '../../assets/linkedin.svg'
import instagram from '../../assets/instagram.svg'
import { IPortalHardCodeLink } from '@/store/modules/links/interfaces/IPortalHardCodeLink'

export default defineComponent({
  name: 'SocialBannerNew',
  data () {
    return {
      facebook,
      linkedin,
      instagram
    }
  },
  computed: {
    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    }
  },
  methods: {
    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    }
  }
})
