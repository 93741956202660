import { ActionTree, MutationTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { magnoliaServiceAPI } from '@/store/api-instance'
import { IRootState } from '@/store'
import {
  IHelpPagesState,
  IFetchHelpPagesRequest,
  IFetchHelpPagesResponse,
  HelpPagesMutationType,
  IHelpPagesMutations,
  HelpPagesActionType,
  IHelpPagesActions,
  IHelpPages
} from './interfaces'

const state = (): IHelpPagesState => ({
  helpPages: [],
  loading: false
})

const mutations: MutationTree<IHelpPagesState> & IHelpPagesMutations = {
  [HelpPagesMutationType.SET_LOADING] (state: IHelpPagesState, payload: boolean) {
    state.loading = payload
  },
  async [HelpPagesMutationType.SET_ALL_HELP_PAGES] (state: IHelpPagesState, payload: Array<IHelpPages>) {
    state.helpPages = payload
  }
}

const fetchHelpPages = async (dto: IFetchHelpPagesRequest): Promise<IFetchHelpPagesResponse> => {
  const response: AxiosResponse = await magnoliaServiceAPI.get<IFetchHelpPagesResponse>('helppages', { params: dto })
  return response.data
}

const fetchAllHelpPages = async (
  dto: IFetchHelpPagesRequest,
  oldData?: Array<IHelpPages>
): Promise<Array<IHelpPages>> => {
  const { results, total, limit } = await fetchHelpPages(dto)

  oldData = (oldData || []).concat(results)

  if (total > oldData.length) {
    return await fetchAllHelpPages({ ...dto, offset: oldData.length, limit }, oldData)
  }

  return oldData
}

const actions: ActionTree<IHelpPagesState, IRootState> & IHelpPagesActions = {
  async [HelpPagesActionType.FETCH_ALL_HELP_PAGES] ({ commit }, dto: IFetchHelpPagesRequest) {
    commit(HelpPagesMutationType.SET_LOADING, true)
    const data = await fetchAllHelpPages(dto)
    commit(HelpPagesMutationType.SET_ALL_HELP_PAGES, data)
    commit(HelpPagesMutationType.SET_LOADING, false)
  }
}

export default {
  state,
  mutations,
  actions
}
