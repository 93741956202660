<template>
  <router-link to='/'>
<svg width="122" height="42" fill= "#ba975c" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.33 66">
  <defs>
  </defs>
  <g id="artwork">
    <g>
      <g>
        <path class="cls-1" d="M80.14,15.95h6.7c1.18,0,2.24.11,3.19.32.95.21,1.76.56,2.44,1.04.68.48,1.2,1.1,1.57,1.86s.55,1.66.55,2.73c0,1.14-.21,2.1-.64,2.87s-1,1.4-1.73,1.87c-.72.47-1.57.82-2.54,1.03-.97.21-1.99.32-3.07.32h-2.81v8.5h-3.65V15.95ZM86.35,24.91c.6,0,1.17-.04,1.71-.13.54-.09,1.02-.24,1.45-.46.43-.22.76-.53,1.02-.91.25-.39.38-.89.38-1.51s-.13-1.09-.38-1.46c-.25-.38-.58-.67-1-.88-.42-.21-.89-.35-1.42-.42-.53-.07-1.08-.1-1.64-.1h-2.67v5.89h2.55Z"/>
        <path class="cls-1" d="M97.83,15.95h7.13c.97,0,1.9.1,2.81.3.91.2,1.72.54,2.42,1,.71.46,1.27,1.07,1.7,1.83.42.75.64,1.67.64,2.75,0,1.49-.42,2.7-1.26,3.64-.84.94-1.99,1.57-3.44,1.9l5.48,9.11h-4.41l-4.76-8.7h-2.67v8.7h-3.65V15.95ZM104.47,24.79c.52,0,1.04-.04,1.57-.12.52-.08.99-.22,1.41-.43.42-.21.76-.52,1.03-.91.27-.4.41-.9.41-1.52,0-.56-.13-1.02-.38-1.38-.25-.36-.57-.64-.96-.86-.39-.21-.83-.36-1.32-.43-.49-.08-.97-.12-1.44-.12h-3.31v5.77h2.99Z"/>
        <path class="cls-1" d="M122.51,15.95h3.16l8.85,20.53h-4.18l-1.91-4.7h-8.9l-1.86,4.7h-4.09l8.93-20.53ZM127.15,28.65l-3.16-8.35-3.22,8.35h6.38Z"/>
        <path class="cls-1" d="M135.3,33.17l11.17-14.04h-10.99v-3.19h15.34v3.31l-11.22,13.98h11.4v3.25h-15.69v-3.31Z"/>
        <path class="cls-1" d="M154.27,15.95h7.28c1.33,0,2.68.17,4.03.52,1.35.35,2.57.92,3.65,1.73,1.08.8,1.96,1.86,2.64,3.16.68,1.31,1.01,2.92,1.01,4.86,0,1.8-.34,3.35-1.01,4.64-.68,1.3-1.56,2.36-2.64,3.19-1.08.83-2.3,1.45-3.65,1.84-1.35.4-2.7.59-4.03.59h-7.28V15.95ZM160.97,33.29c.95,0,1.89-.11,2.84-.33.95-.22,1.8-.6,2.57-1.13.76-.53,1.39-1.25,1.87-2.16.48-.91.72-2.06.72-3.45,0-1.47-.24-2.67-.72-3.6-.48-.93-1.11-1.65-1.87-2.18-.76-.52-1.62-.88-2.57-1.07-.95-.19-1.9-.29-2.84-.29h-3.04v14.21h3.04Z"/>
        <path class="cls-1" d="M176.42,15.95h7.13c.97,0,1.9.1,2.81.3.91.2,1.72.54,2.42,1,.71.46,1.27,1.07,1.7,1.83.42.75.64,1.67.64,2.75,0,1.49-.42,2.7-1.26,3.64-.84.94-1.99,1.57-3.44,1.9l5.48,9.11h-4.41l-4.76-8.7h-2.67v8.7h-3.65V15.95ZM183.06,24.79c.52,0,1.04-.04,1.57-.12.52-.08.99-.22,1.41-.43.42-.21.76-.52,1.03-.91.27-.4.41-.9.41-1.52,0-.56-.13-1.02-.38-1.38-.25-.36-.57-.64-.96-.86-.39-.21-.83-.36-1.32-.43-.49-.08-.97-.12-1.44-.12h-3.31v5.77h2.99Z"/>
        <path class="cls-1" d="M193.68,26.15c0-1.64.28-3.13.83-4.45.55-1.32,1.31-2.45,2.29-3.38.98-.93,2.14-1.64,3.48-2.15,1.34-.5,2.81-.75,4.39-.75s3.08.25,4.44.75c1.35.5,2.52,1.22,3.51,2.15.99.93,1.75,2.05,2.31,3.38.55,1.32.83,2.81.83,4.45s-.28,3.07-.83,4.39c-.55,1.32-1.32,2.47-2.31,3.42-.99.96-2.16,1.7-3.51,2.23-1.35.53-2.83.8-4.44.8s-3.05-.27-4.39-.8c-1.34-.53-2.5-1.28-3.48-2.23-.98-.96-1.74-2.1-2.29-3.42-.55-1.32-.83-2.79-.83-4.39ZM197.65,26.15c0,1.1.17,2.11.51,3.03.34.92.82,1.72,1.45,2.39.63.68,1.37,1.2,2.23,1.58.86.38,1.81.57,2.86.57s2-.19,2.87-.57,1.62-.9,2.25-1.58c.63-.68,1.11-1.47,1.45-2.39.34-.92.51-1.93.51-3.03s-.17-2.05-.51-2.96c-.34-.91-.82-1.7-1.44-2.38-.62-.68-1.36-1.21-2.23-1.59-.87-.39-1.84-.58-2.9-.58s-2.03.19-2.89.58c-.86.39-1.6.92-2.22,1.59-.62.68-1.1,1.47-1.44,2.38-.34.91-.51,1.9-.51,2.96Z"/>
        <path class="cls-1" d="M228.33,29.84c0,.75-.08,1.55-.25,2.38-.16.83-.47,1.6-.91,2.32s-1.07,1.3-1.88,1.77c-.81.46-1.86.7-3.13.7-1.66,0-2.99-.42-3.99-1.25-1-.83-1.68-1.92-2.04-3.28l3.36-.96c.17.68.49,1.21.96,1.61.46.4,1.02.59,1.65.59.52,0,.95-.1,1.29-.29.34-.19.6-.47.78-.83.18-.36.31-.78.39-1.28.08-.49.12-1.03.12-1.61v-13.78h3.65v13.89Z"/>
        <path class="cls-1" d="M79.16,55.15c0-1.64.28-3.13.83-4.45.55-1.32,1.31-2.45,2.29-3.38.98-.93,2.14-1.64,3.48-2.15,1.34-.5,2.81-.75,4.39-.75s3.08.25,4.44.75c1.35.5,2.52,1.22,3.51,2.15.99.93,1.75,2.05,2.31,3.38.55,1.32.83,2.81.83,4.45s-.28,3.07-.83,4.39c-.55,1.32-1.32,2.47-2.31,3.42-.99.96-2.16,1.7-3.51,2.23-1.35.53-2.83.8-4.44.8s-3.05-.27-4.39-.8c-1.34-.53-2.5-1.28-3.48-2.23-.98-.96-1.74-2.1-2.29-3.42-.55-1.32-.83-2.79-.83-4.39ZM83.13,55.15c0,1.1.17,2.11.51,3.03.34.92.82,1.72,1.45,2.39.63.68,1.37,1.2,2.23,1.58.86.38,1.81.57,2.86.57s2-.19,2.87-.57,1.62-.9,2.25-1.58c.63-.68,1.11-1.47,1.45-2.39.34-.92.51-1.93.51-3.03s-.17-2.05-.51-2.96c-.34-.91-.82-1.7-1.44-2.38-.62-.68-1.36-1.21-2.23-1.59-.87-.39-1.84-.58-2.9-.58s-2.03.19-2.89.58c-.86.39-1.6.92-2.22,1.59-.62.68-1.1,1.47-1.44,2.38-.34.91-.51,1.9-.51,2.96Z"/>
        <path class="cls-1" d="M104.77,44.95h4.84l9.54,15.37h.06v-15.37h3.65v20.53h-4.64l-9.74-15.86h-.06v15.86h-3.65v-20.53Z"/>
        <path class="cls-1" d="M127.39,44.95h3.65v17.28h8.73v3.25h-12.38v-20.53Z"/>
        <path class="cls-1" d="M142.2,44.95h3.65v20.53h-3.65v-20.53Z"/>
        <path class="cls-1" d="M150.38,44.95h4.84l9.54,15.37h.06v-15.37h3.65v20.53h-4.64l-9.74-15.86h-.06v15.86h-3.65v-20.53Z"/>
        <path class="cls-1" d="M173,44.95h13.6v3.19h-9.95v5.22h9.42v3.07h-9.42v5.8h10.47v3.25h-14.12v-20.53Z"/>
      </g>
      <g>
        <path class="cls-1" d="M70.03,65.63h-16.76v-20.59c0-1.91-1.46-3.49-3.27-3.51h-.3s-.86-1.32-1.55-2h1.8c2.96.03,5.31,2.5,5.31,5.51v18.59s12.76,0,12.76,0v-22.12h-2.33v-1c0-1.66-.76-3.21-2.03-4.15-.26-.18-.51-.33-.77-.45-.26-.12-.51-.21-.78-.27l-.63-.15-2.03-10.47h3.88v-3.2h-4.52l-.65-6.16v-5.31h-2.23l.08,5.04h-9.76l-3.03-3.28h-3.95v-3.69c0-1.53-.69-2.96-1.85-3.83-.08-.05-.15-.09-.2-.12-.34-.23-.66-.4-.98-.5l-.78-.26.1-.81c0-.07.02-.13.03-.2,0-.12-.06-.29-.18-.46-.07-.08-.22-.23-.41-.23-.32,0-.59.33-.59.73l.16.86-.81.36c-1.81.6-3.03,2.39-3.03,4.46v3.69h-3.95l-3.04,3.28h-9.76l.02-1.02s.06-2.55.09-4.01h-2.23v5.42s-.69,6.04-.69,6.04h-4.51v3.2h3.88l-2.04,10.47-.62.15c-2.07.5-3.58,2.55-3.58,4.88v1h-2.33v22.12h12.78l-.02-23.1c0-5.48,4.14-9.92,9.23-9.92,1.95,0,3.82.66,5.4,1.91,0,0-3.9.09-5.4.09-3.99,0-7.23,3.55-7.23,7.91l.02,25.12H0v-26.12h2.39c.35-2.62,2.04-4.8,4.41-5.64l1.34-6.86h-3.46v-7.2h4.73l.47-4.21v-7.26h6.25v1c0,.15-.06,2.6-.09,4.04h6.84l3.04-3.28h2.82v-1.69c0-2.66,1.46-5.01,3.7-6.08.18-1.32,1.26-2.33,2.56-2.33.77,0,1.48.36,2.01,1.02.31.43.5.88.56,1.33.26.13.52.29.79.47.12.08.19.12.24.16,1.68,1.27,2.67,3.3,2.67,5.45v1.69h2.83l3.03,3.28h6.85l-.08-5.04h6.26v7.26l.45,4.21h4.72v7.2h-3.45l1.33,6.86c.18.06.35.14.53.22.36.17.71.38,1.08.64,1.53,1.13,2.53,2.87,2.79,4.78h2.39v26.12Z"/>
        <path class="cls-1" d="M36.6,65.63h-10.04v-30.04h6.91c4.31,0,7.07,1.85,8.71,4.05,2.98,1.02,5.86,3.25,5.86,7.58,0,5.55-4.07,9-10.63,9h-.81v9.41ZM28.56,63.63h6.04v-9.41h2.81c2.59,0,8.63-.68,8.63-7,0-2.19-1-3.63-2.31-4.56.31.96.45,1.86.45,2.58,0,3.67-2.67,6.34-6.34,6.34s-6.34-2.84-6.34-6.34,2.84-6.34,6.34-6.34c.21,0,.44,0,.67.02-1.25-.79-2.89-1.33-5.05-1.33h-4.91v26.04ZM37.84,40.91c-2.39,0-4.34,1.95-4.34,4.34s1.95,4.34,4.34,4.34c2.56,0,4.34-1.78,4.34-4.34,0-.72-.29-2.32-1.26-3.87-1.64-.46-3.04-.47-3.08-.47Z"/>
      </g>
    </g>
  </g>
</svg>
  </router-link>
</template>

<script>
</script>
