
import AsahiButton from '../library/AsahiButton.vue'
import AsahiRadioButton from '@/components/library/AsahiRadioButton.vue'
import { defineComponent } from 'vue'
import AsahiTypography from '@/components/library/Typography.vue'

export default defineComponent({
  name: 'OptionsModal',

  components: {
    AsahiTypography,
    AsahiRadioButton,
    AsahiButton
  },
  data () {
    return {
      selectedOption: this.selectedValue
    }
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {
        /*  */
      }
    },
    title: String,
    subtitle: String,
    description: String,
    icon: String,
    options: Array,
    selectedValue: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeModal', 'chooseOption'],
  methods: {
    selectOption (e: any) {
      this.selectedOption = e.value
    },
    applyChanges () {
      if (this.selectedOption) {
        this.$emit('chooseOption', { value: this.selectedOption })
        this.closeModal()
      }
    }
  }
})

