
import { defineComponent } from 'vue'
import { setI18nLanguage } from '@/i18n'
import { LanguagesType } from '@/types'
import { constants } from '@/constants'
import AsahiLogoNew from '@/components/library/assets/AsahiLogoNew.vue'
import AsahiLanguageSelector from '@/components/library/AsahiLanguageSelector.vue'
import AsahiButton from '@/components/library/AsahiButton.vue'

export default defineComponent({
  name: 'BeforeLoginLayout',
  components: {
    AsahiLanguageSelector,
    AsahiButton,
    AsahiLogoNew
  },
  data: () => ({
    activeLanguage: (localStorage.getItem('lang')) as LanguagesType,
    theme: constants.application.theme
  }),

  inject: ['isPeroni'],

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    languages () {
      if (this.user.country === 'CZE' || window.location.hostname.includes('.cz')) {
        return ['cs', 'en']
      }

      if (this.user.country === 'SVK' || window.location.hostname.includes('.sk')) {
        return ['sk', 'en']
      }

      return ['cs', 'en', this.activeLanguage].filter((x, index, self) => x != null && self.indexOf(x) === index)
    }
  },
  mounted () {
    if (localStorage?.getItem('lang') === 'null') {
      this.activeLanguage = this.languages[0] as LanguagesType
    } else {
      this.activeLanguage = (localStorage?.getItem('lang') || this.languages[0]) as LanguagesType
    }
    setI18nLanguage(this.activeLanguage)
  },

  methods: {
    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    }
  }
})
