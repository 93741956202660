<template>
  <div class="container-fluid" v-if="items.length > 0">
    <div class="row">
      <div class="col">
        <nav>
          <ul class="breadcrumb-list">
            <li class="arrow-left"><img :src='chevronLeft' /></li>
            <li class="menuitem"><a href="javascript:void(0);" @click="$router.back()"><span class="text-primary font-semibold">{{$t('Back')}}</span></a></li>
            <li class="menuitem" v-for="(crumb, index) in items" :key="index">
              <router-link :to="crumb.route"><span class="text-primary font-semibold">{{ $t(crumb.label) }}</span></router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import chevronLeft from '@/assets/icons/library/chevron-left-gold.svg'
export default {
  name: 'BreadcrumbLayout',
  data () {
    return {
      chevronLeft
    }
  },
  computed: {
    items: function () {
      const labelArray = this.$route.meta.breadCrumb ?? []
      const breadcrumbs = labelArray.map((item) => {
        return {
          path: this.$route.path,
          route: item.path,
          label: item.text
        }
      })
      return breadcrumbs ?? []
    }
  }
}
</script>
<style scoped lang='scss'>
.container{
  margin: auto;
  width: 50%
}
.breadcrumb-list {
    display: flex;
    list-style-type: none !important;
    align-items: center !important;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;

    width: 100%;
    height: 23px;
    /*
    position: absolute;
    left: 0px;
    top: 75px;
    */
}
@include media-breakpoint-down(lg) {
  .breadcrumb-list {
    display: none;
  }
}

.breadcrumb-list > li.menuitem {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 1px;
  width: auto;
  height: 23px;
  border-bottom: 1px solid $breadcrumb-yellow;
}

.text-primary {
    color: $breadcrumb-yellow !important;
}

.breadcrumb-list .arrow-left {
  margin-right: -10px !important;
}
</style>
