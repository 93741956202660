
import { defineComponent } from 'vue'
import AsahiButton from '@/components/library/AsahiButton.vue'
import AsahiTypography from '@/components/library/Typography.vue'

export default defineComponent({
  name: 'AsahiInformationModal',
  components: {
    AsahiButton,
    AsahiTypography
  },

  emits: ['confirm', 'cancel'],

  props: {
    title: String,
    message: String,
    subMessage: String,
    icon: String,
    cancel: String,
    confirm: {
      type: String,
      default: 'OK'
    },
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  computed: {
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    onConfirm () {
      this.$emit('confirm')
      this.closeModal()
    },
    onCancel () {
      this.closeModal()
    }
  }
})
