import { IRootState } from '@/store'
import { ActionContext } from 'vuex'

export interface IPageContents {
  locationId: string
  id: string,
  name: string
  title: string
  body: string
}

export interface IFetchPageContentRequest {
    limit?: number
    offset?: number
    market?: string
    language?: string
    sortType?: string
    locationId?: boolean
  }

export interface IPageContentsState {
    pageContents: Array<IPageContents>
    loading: boolean
  }

export enum PageContentsMutationType {
    SET_LOADING = 'SET_LOADING',
    SET_ALL_PAGE_CONTENTS = 'SET_ALL_PAGE_CONTENTS',
    ADD_PAGE_CONTENTS = 'ADD_PAGE_CONTENTS'
  }

export enum PageContentsActionType {
    FETCH_ALL_PAGE_CONTENTS = 'FETCH_ALL_PAGE_CONTENTS',
    FETCH_PAGE_CONTENTS = 'FETCH_PAGE_CONTENTS'
  }

export interface IPageContentsMutations<S = IPageContentsState> {
    [PageContentsMutationType.SET_LOADING](state: S, payload: boolean): void
    [PageContentsMutationType.SET_ALL_PAGE_CONTENTS](state: S, payload: Array<IPageContents>): void
    [PageContentsMutationType.ADD_PAGE_CONTENTS](state: S, payload: Array<IPageContents>): void
  }

export type AugmentedPageContentsActionsType = {
    commit<K extends keyof IPageContentsMutations>(
      key: K,
      payload: Parameters<IPageContentsMutations[K]>[1]
    ): ReturnType<IPageContentsMutations[K]>
  } & Omit<ActionContext<IPageContentsState, IRootState>, 'commit'>

export interface IFetchPageContentResponse {
    total: number
    offset: number
    limit: number
    results: Array<IPageContents>
  }

export interface IPageContentsActions {
    [PageContentsActionType.FETCH_PAGE_CONTENTS](
      { commit }: AugmentedPageContentsActionsType,
      payload: IFetchPageContentRequest
    ): void
  }
