import {
  ICategory,
  ICreateTicketRequest,
  IFetchAttachmentResponse,
  IFetchCommentResponse,
  IFetchTicketsRequest,
  IFetchTicketsResponse,
  IFetchUpdateTicketRequest,
  IReadCommentTicketRequest,
  ISubCategory
} from '@/store/modules/tickets/interfaces'
import { AxiosResponse } from 'axios'
import { generalServiceV1API } from '@/store/api-instance'
import { constants } from '@/constants'

export const ticketsAPI = {
  async fetchAllTickets (dto: IFetchTicketsRequest): Promise<IFetchTicketsResponse> {
    const response: AxiosResponse = await generalServiceV1API.get<IFetchTicketsResponse>('tickets/GetAllTickets', {
      params: dto
    })
    return response.data
  },
  async fetchCategories (): Promise<ICategory[]> {
    const response: AxiosResponse<ICategory[]> = await generalServiceV1API.get('tickets/GetCategories')
    return response.data
  },
  async fetchSubCategories (id: string): Promise<ISubCategory[]> {
    const response: AxiosResponse<ISubCategory[]> = await generalServiceV1API.get(
      `tickets/GetSubCategoriesByCategoryId/${id}`
    )
    return response.data
  },
  async fetchAllSubCategories (): Promise<ISubCategory[]> {
    const response: AxiosResponse<ISubCategory[]> = await generalServiceV1API.get('tickets/GetAllSubCategories')
    return response.data
  },
  async fetchCreateComment (dto: Omit<IFetchUpdateTicketRequest, 'attachments'>): Promise<IFetchCommentResponse> {
    const response: AxiosResponse<IFetchCommentResponse> = await generalServiceV1API.post('tickets/CreateComment', {
      ...dto
    })
    return response.data
  },
  async fetchUploadAttachment (
    dto: Pick<IFetchUpdateTicketRequest, 'attachments' | 'ticketId'>
  ): Promise<IFetchAttachmentResponse> {
    const response: AxiosResponse<IFetchAttachmentResponse> = await generalServiceV1API.post(
      `tickets/UploadAttachmentsByTicketId?ticketId=${dto.ticketId}`,
      dto.attachments
    )
    return response.data
  },
  async fetchCommentsByTicket (id: string, limit = 100): Promise<IFetchCommentResponse> {
    try {
      const response: AxiosResponse<IFetchCommentResponse> = await generalServiceV1API.get(
        `tickets/GetCommentsByTicketId/${id}?limit=${limit}`
      )
      return response.data
    } catch (err) {
      console.log(err)
      return { commentsList: [] }
    }
  },
  async readCommentsByTicket (dto: IReadCommentTicketRequest): Promise<any> {
    try {
      const response: AxiosResponse<IReadCommentTicketRequest> = await generalServiceV1API.post(
        `tickets/SetCommentsAsRead?ticketId=${dto.ticketId}`
      )
      return response.data
    } catch (error) {
      console.error('Error in readCommentsByTicket API call:', error) // Log errors
      throw error // Ensure errors are propagated
    }
  },
  async fetchAttachmentsByTicket (id: string): Promise<IFetchAttachmentResponse> {
    try {
      const response: AxiosResponse<IFetchAttachmentResponse> = await generalServiceV1API.get(
        `tickets/GetAttachmentsByTicketId/${id}`
      )
      return response.data
    } catch (err) {
      console.log(err)
      return { attachmentList: [] }
    }
  },
  async createTicket (dto: ICreateTicketRequest): Promise<any> {
    const market = constants.application.theme === 'peroni' ? 'IT' : 'CZ'
    const response: AxiosResponse = await generalServiceV1API.post<any>(`tickets/CreateTicket?market=${market}`, dto)
    return response.data
  }
}

// user need tto be CDC user ID
