import gql from 'graphql-tag'

export const fetchWishlistQuery = gql`
  query (
    $currentPage: Int = 1
    $pageSize: Int = 25
  ) {
    customer {
      wishlists {
        id
        items_count
        items_v2 (pageSize: $pageSize, currentPage: $currentPage){
          shipto_item_count
          items {
            id
            quantity
            product {
              uid
              sku
            }
          }
        }
      }
    }
  }
`
