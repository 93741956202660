import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-open-sans" }
const _hoisted_2 = { class: "main-view" }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "main-view" }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { class: "main-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppGlobalModal = _resolveComponent("AppGlobalModal")!
  const _component_UserToolbar = _resolveComponent("UserToolbar")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_TutorialModal = _resolveComponent("TutorialModal")!
  const _component_ResizeObserver = _resolveComponent("ResizeObserver")!
  const _component_error401_page = _resolveComponent("error401-page")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_BeforeLoginLayout = _resolveComponent("BeforeLoginLayout")!
  const _component_message_modal = _resolveComponent("message-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_AppGlobalModal),
          _createVNode(_component_UserToolbar),
          (!_ctx.isPOSAcademyUser)
            ? (_openBlock(), _createBlock(_component_ModalComponent, {
                key: 0,
                ref: "tutorialModal",
                showCloseButton: _ctx.user.firstLogin !== true,
                backdropDismiss: _ctx.user.firstLogin !== true
              }, null, 8, ["showCloseButton", "backdropDismiss"]))
            : _createCommentVNode("", true),
          (_ctx.showTutorialModal)
            ? (_openBlock(), _createBlock(_component_TutorialModal, {
                key: 1,
                closeModal: _ctx.closeTutorialModal
              }, null, 8, ["closeModal"]))
            : _createCommentVNode("", true),
          (_ctx.isPortalChrome && _ctx.isLoggedIn && !(_ctx.gigICode && _ctx.gigIvToken))
            ? (_openBlock(), _createBlock(_component_AppLayout, { key: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_ResizeObserver, { onResize: _ctx.onResizePortal }, null, 8, ["onResize"]),
                        (_ctx.notAuthorized)
                          ? (_openBlock(), _createBlock(_component_error401_page, { key: 0 }))
                          : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : (_ctx.isPortalChrome && !_ctx.isLoggedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_BeforeLoginLayout, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view, null, {
                        default: _withCtx(({ Component }) => [
                          _createElementVNode("div", _hoisted_4, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_AppLayout, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view, null, {
                        default: _withCtx(({ Component }) => [
                          _createElementVNode("div", _hoisted_6, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])),
          (_ctx.openModal)
            ? (_openBlock(), _createBlock(_component_message_modal, {
                key: 5,
                message: _ctx.message,
                subMessage: _ctx.subMessage,
                isErrorModal: _ctx.isErrorModal,
                onClose: _cache[0] || (_cache[0] = 
          () => {
            _ctx.openModal = false
          }
        )
              }, null, 8, ["message", "subMessage", "isErrorModal"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}