<template>
  <svg :width="widthValue" :height="heightValue" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.49885 0C8.9426 0 9.35197 0.234375 9.57697 0.61875L16.327 12.1187C16.5551 12.5062 16.5551 12.9844 16.3332 13.3719C16.1113 13.7594 15.6957 14 15.2488 14H1.74885C1.30197 14 0.886347 13.7594 0.664472 13.3719C0.442597 12.9844 0.445722 12.5031 0.670722 12.1187L7.42072 0.61875C7.64572 0.234375 8.0551 0 8.49885 0ZM8.49885 4C8.08322 4 7.74885 4.33437 7.74885 4.75V8.25C7.74885 8.66562 8.08322 9 8.49885 9C8.91447 9 9.24885 8.66562 9.24885 8.25V4.75C9.24885 4.33437 8.91447 4 8.49885 4ZM9.49885 11C9.49885 10.7348 9.39349 10.4804 9.20595 10.2929C9.01842 10.1054 8.76406 10 8.49885 10C8.23363 10 7.97928 10.1054 7.79174 10.2929C7.6042 10.4804 7.49885 10.7348 7.49885 11C7.49885 11.2652 7.6042 11.5196 7.79174 11.7071C7.97928 11.8946 8.23363 12 8.49885 12C8.76406 12 9.01842 11.8946 9.20595 11.7071C9.39349 11.5196 9.49885 11.2652 9.49885 11Z" fill="#C6243E"/>
  </svg>
</template>

<script>
export default {
  name: 'AllertCart',

  props: {
    heightValue: Number,
    widthValue: Number
  }
}

</script>
<style lang='scss'>
</style>
