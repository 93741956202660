import gql from 'graphql-tag'

export const fetchProductQuery = gql`
  query ($sku: String) {
    products(filter: { sku: { eq: $sku } }, pageSize: 25, currentPage: 1, sort: { catalog_product_name: ASC }) {
      items {
        multi_deposit
        material_type
        number_of_items_in_box
        deliveryDate
        sku
        number_of_products_on_layer
        description {
          html
        }
        ean
        ean_ea
        isavailable_truck_package_ordering
        your_price
        your_price_tax
        downloads
        favourites
        promo
        weight
        gross_weight_for_one_cas
        other_info_gross_weight
        sap_sub_brand_code
        pallet_weight
        packaging
        options_container
        beer_type
        number_of_layers_per_pallet
        number_of_items_on_pallet
        number_of_products_on_layer
        product_name_sap
        sku_product_detail_page
        brand
        other_info_alcohol
        hectolitres_for_one_cas_frontend
        hectolitres_per_pallet
        other_info_shelf_life
        pack_format
        pack_material
        sap_pack
        sap_pack_configuration
        returnable_package
        sub_brand
        volume
        country_of_origin
        catalog_product_name
        name
        filter_packaging
        product_last
        product_new
        product_sale
        product_recommended
        canonical_url
        url_path
        url_key
        url_rewrites {
          url
          parameters {
            name
            value
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        swatch_image
        small_image {
          url
        }
        thumbnail {
          url
        }
        image {
          url
        }
        media_gallery {
          url
        }
        related_products {
          uid
          name
          sku
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
            maximum_price {
              final_price {
                value
                currency
              }
            }
          }
          swatch_image
          small_image {
            url
          }
          thumbnail {
            url
          }
          image {
            url
          }
          media_gallery {
            url
          }
        }
        ... on BundleProduct {
          your_price_sum
          your_price_sum_tax
          bundle_price_sum
          bundle_price_sum_tax
          bundle_fields {
            minimum_amt
            valid_from
            valid_to
            limited_amount
          }
          bundle_product_details {
            sku
            catalog_product_name
            bundle_quantity
            price_per_unit_w_vat
            price_per_unit_wo_vat
            price_per_unit_wo_vat_discounted
            price_per_unit_w_vat_discounted
          }
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum
            your_price_sum_tax
            bundle_price
            options {
              uid
              id
              label
              quantity
              can_change_quantity
              price
              price_type
              is_default
              position
              product {
                id
                name
                sku
                sku_product_detail_page
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                  }
                }
                your_price
                your_price_tax
              }
            }
          }
        }
      }
      page_info {
        page_size
      }
    }
  }
`
