
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import AsahiProgress from '@/components/library/AsahiProgress.vue'
import AllertCart from '@/components/library/assets/AllertCart.vue'
import SuccessCart from '@/components/library/assets/SuccessCart.vue'
import formatDateMixin from '@/utils/mixin'

const HOMEPAGE_URL = '/'

export default defineComponent({
  components: {
    SuccessCart,
    AllertCart,
    AsahiProgress
  },
  mixins: [formatDateMixin],
  props: {
    changeMessageColor: {
      type: Boolean,
      default: false
    },
    messageType: {
      type: String,
      default: 'header'
    },
    mobile: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      errorWeight: false,
      successWeight: false,
      isComponentVisible: false,
      errorWeightBar: false,
      isMobile: (window.innerWidth <= 768) || (this.messageType === 'mobile')
    }
  },
  computed: {
    ...mapState('basket', ['id', 'query', 'items', 'paymentMethod', 'shipmentId']),
    ...mapGetters('basket', ['progressBarSuccess', 'showProgressBarMessage']),
    ...mapState('catalog', ['alignment', 'shipTos']),

    progressBar () {
      return this.query?.progress_bar_data
    },

    isHomePge () {
      return this.$route.path === HOMEPAGE_URL
    },
    maxOrderCash () {
      return this.progressBar?.iMaximumOrderCash ?? 0
    },
    grandTotalYourPrice () {
      return this.query?.grand_total_your_price_details?.grand_total_your_price ?? 0
    },
    cashFilledPercentage () {
      return (this.grandTotalYourPrice / this.maxOrderCash) * 100
    },
    differenceResult () {
      return Math.round(this.maxOrderCash - this.grandTotalYourPrice)
    },
    totalCartPallet () {
      return this.progressBar?.iTotalCartPalletQty ?? 0
    },
    minimumOrderPallett () {
      return this.progressBar?.iMinimumOrderPallet ?? 0
    },

    messageColorClass () {
      if (this.changeMessageColor) {
        if (this.progressBarMessage.hasError) {
          return 'border-error-text'
        } else if (this.progressBarMessage.hasSucccess) {
          return 'border-success-text'
        } else {
          return 'border-default-text'
        }
      }
      return 'border-default-text'
    },

    isCompactAndFullTruck () {
      return this.messageType === 'compact' && this.progressBar?.isFullTruck === true
    },
    isCompactAndNotFullTruck () {
      return this.messageType === 'compact' && this.progressBar?.isFullTruck === false
    },

    progressBarMessage () {
      const difference = (this.maxOrderCash - this.grandTotalYourPrice).toFixed(2)
      if (this.isEmptyTransportType) {
        if (this.totalCartPallet < this.minimumOrderPallett) {
          return {
            title: `${this.$t('Reach minimum number of pallets:')} <b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b>`,
            subtitle: `${this.$t('The minimum quantity of pallet has not been reached, please add more goods.')}`,
            buttonTitle: this.$t('Min Pallets', { n: this.minimumOrderPallett }),
            hasError: false,
            hasSucccess: false
          }
        } else if (this.totalCartPallet > this.progressBar?.iMaximumOrderPallet) {
          return {
            title: this.$t('The maximum number of pallets has been exceeded.'),
            subtitle: this.$t('The maximum number of pallets has been exceeded.'),
            buttonTitle: `${this.$t('Max')} ${this.minimumOrderPallett} ${this.$t('pallets')}`,
            hasError: true,
            hasSucccess: false
          }
        } else if (this.totalCartPallet === this.minimumOrderPallett) {
          return {
            title: `${this.$t('Reach minimum number of pallets:')} <b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b>`,
            subtitle: this.$t('The minimum order capacity has been reached.'),
            buttonTitle: '',
            hasError: false,
            hasSucccess: true
          }
        }
      } else if (this.progressBar?.isFullTruck) {
        if (this.totalCartPallet <= 0) {
          return {
            title: `${this.$t('Reach minimum number of pallets:')} <b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b>`,
            subtitle: `${this.$t('The minimum number of pallets has not yet been reached, please add more goods.')}`,
            buttonTitle: this.$t('Min Pallets', { n: this.minimumOrderPallett }),
            hasError: false,
            hasSucccess: false
          }
        }
        if (
          this.totalCartPallet < this.progressBar?.iMinimumOrderPallet &&
          this.progressBar?.iTotalCartWeight < this.progressBar?.iMinimumOrderWeight
        ) {
          return {
            title: `${this.$t('Reach minimum number of pallets:')} <b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b>`,
            subtitle: `${this.$t('The minimum number of pallets has not yet been reached, please add more goods.')}`,
            buttonTitle: this.$t('Min Pallets', { n: this.minimumOrderPallett }),
            hasError: true,
            hasSucccess: false
          }
        } else if (this.progressBar.iTotalCartWeight > this.progressBar.iMaximumOrderWeight) {
          return {
            title: `${this.$t('Exceeded maximum weight:')} <b>${this.progressBar?.iTotalCartWeight}Kg / ${this.progressBar?.iMaximumOrderWeight}Kg</b>`,
            subtitle: this.$t('The maximum weight of order has been exceeded.'),
            buttonTitle: this.$t('Max weight', { n: this.progressBar.iMaximumOrderWeight }),
            hasError: true,
            hasSucccess: false
          }
        } else if (this.totalCartPallet > this.progressBar?.iMaximumOrderPallet) {
          return {
            title: this.$t('The maximum number of pallets has been exceeded.'),
            subtitle: this.$t('The maximum number of pallets has been exceeded.'),
            buttonTitle: `${this.$t('Max')} ${this.minimumOrderPallett} ${this.$t('pallets')}`,
            hasError: true,
            hasSucccess: false
          }
        } else if (
          this.totalCartPallet >= this.progressBar?.iMinimumOrderPallet &&
          this.totalCartPallet < this.progressBar?.iMaximumOrderPallet &&
          this.progressBar.iTotalCartWeight < this.progressBar.iMinimumOrderWeight
        ) {
          return {
            title: this.$t('The minimum weight of order has not been reached'),
            subtitle: this.$t('The minimum weight of order has not been reached'),
            buttonTitle: '',
            hasError: true,
            hasSucccess: false
          }
        } else if (
          this.totalCartPallet === this.progressBar?.iMaximumOrderPallet &&
          this.progressBar?.iTotalCartWeight <= this.progressBar?.iMaximumOrderWeight
        ) {
          return {
            title: `${this.$t('Reach minimum number of pallets:')} <b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b>`,
            subtitle: this.$t('The minimum order capacity has been reached.'),
            buttonTitle: '',
            hasError: false,
            hasSucccess: true
          }
        } else {
          return {
            title: `${this.$t('minimum order capacity reached')} <b>${this.progressBar?.iTotalCartWeight}Kg / ${this.progressBar?.iMaximumOrderWeight}Kg</b>`,
            subtitle: this.$t('The minimum order capacity has been reached.'),
            buttonTitle: '',
            hasError: false,
            hasSucccess: true
          }
        }
      } else {
        // standard order type
        if (this.grandTotalYourPrice <= 0) {
          return {
            title: `${this.$t('Reach minimum order price')} ${this.formatCurrency(this.maxOrderCash)} `,
            subtitle: `${this.$t('The minimum order capacity has not yet been reached, please add more goods.')}`,
            buttonTitle: `${this.$t('Min')} ${this.formatCurrency(this.maxOrderCash)}`,
            hasError: false,
            hasSucccess: false
          }
        } else if (this.totalCartPallet > this.progressBar?.iMaximumOrderPallet) {
          return {
            title: this.$t('The maximum number of pallets has been exceeded.'),
            subtitle: this.$t('The maximum number of pallets has been exceeded.'),
            buttonTitle: `${this.$t('Max')} ${this.minimumOrderPallett} ${this.$t('pallets')}`,
            hasError: true,
            hasSucccess: false
          }
        } else if (this.totalCartPallet >= this.progressBar?.iMinimumOrderPallet && this.totalCartPallet <= this.progressBar?.iMaximumOrderPallet && this.progressBar.iTotalCartWeight < this.progressBar.iMaximumOrderWeight) {
          return {
            title: this.$t('Minimum number of pallets has been reached.'),
            buttonTitle: '',
            subtitle: '',
            hasError: false,
            hasSucccess: true
          }
        } else if (this.grandTotalYourPrice < this.maxOrderCash) {
          return {
            title: this.isMobile ? `${this.$t('The minimum price of order has not yet been reached, please add more goods.')}` : `${this.$t('Add more')} <b>${this.formatCurrency(parseFloat(difference))}</b> ${this.$t('to reach minimum order capacity')}`,
            subtitle: `${this.$t('The minimum order capacity has not yet been reached, please add more goods.')}`,
            buttonTitle: `${this.$t('Min')} ${this.formatCurrency(this.maxOrderCash)}`,
            mobileTitle: `<b>${this.$t('Add')} ${this.formatCurrency(parseFloat(difference))} ${this.$t('more.')}</b>`,
            hasError: true,
            hasSucccess: false
          }
        } else if (this.grandTotalYourPrice > this.maxOrderCash) {
          return {
            // display in sticky bar/cart summary
            title: this.isMobile ? `${this.$t('minimum order capacity has been reached')}` : `<b>${this.formatCurrency(this.maxOrderCash)}</b> ${this.$t('minimum order capacity reached')}`,
            // display in catalogue page / cart summary below progres bar
            subtitle: `${this.$t('minimum order capacity has been reached')}`,
            // display in cart summary mobile view
            mobileTitle: `<b>${this.formatCurrency(this.maxOrderCash)}</b> ${this.$t('reached')}`,
            hasError: false,
            hasSucccess: true
          }
        }
      }
      return {
        title: '',
        subtitle: ''
      }
    },
    progressBarMiniMessage () {
      const difference = (this.maxOrderCash - this.grandTotalYourPrice).toFixed(2)
      if (this.progressBar?.isFullTruck || this.isEmptyTruck) {
        if (this.totalCartPallet <= 0) {
          return {
            title: `<b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b> ${this.$t('pallets')}`,
            hasError: false,
            hasSucccess: false
          }
        }
        if (this.totalCartPallet < this.progressBar?.iMinimumOrderPallet) {
          // Reach minimum number of pallets:
          return {
            title: `<b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b> ${this.$t('pallets')}`,
            hasError: true,
            hasSucccess: false
          }
        } else if (this.totalCartPallet >= this.progressBar?.iMinimumOrderPallet && this.totalCartPallet <= this.progressBar?.iMaximumOrderPallet && this.progressBar.iTotalCartWeight < this.progressBar.iMaximumOrderWeight) {
          // Minimum number of pallets has been reached
          return {
            title: `<b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b> ${this.$t('pallets')}`,
            hasError: false,
            hasSucccess: true
          }
        } else if (this.totalCartPallet > this.progressBar?.iMaximumOrderPallet) {
          // The maximum number of pallets has been exceeded.
          return {
            title: `<b>${this.totalCartPallet} / ${this.minimumOrderPallett}</b> ${this.$t('pallets')}`,
            hasError: true,
            hasSucccess: false
          }
        } else if (this.progressBar.iTotalCartWeight > this.progressBar.iMaximumOrderWeight) {
          // The maximum weight has been exceeded.
          return {
            title: `<b>${this.progressBar?.iTotalCartWeight}Kg / ${this.progressBar?.iMaximumOrderWeight}Kg</b>`,
            hasError: true,
            hasSucccess: false
          }
        }
      } else {
        if (this.grandTotalYourPrice <= 0) {
          return {
            title: `${this.$t('Reach minimum order price')} ${this.formatCurrency(this.maxOrderCash)}`,
            hasError: false,
            hasSucccess: false
          }
        } else if (this.totalCartPallet >= this.progressBar?.iMinimumOrderPallet && this.totalCartPallet <= this.progressBar?.iMaximumOrderPallet && this.progressBar.iTotalCartWeight < this.progressBar.iMaximumOrderWeight) {
          return {
            title: this.$t('Minimum number of pallets has been reached.'),
            subtitle: '',
            hasError: false,
            hasSucccess: true
          }
        } else if (this.grandTotalYourPrice < this.maxOrderCash) {
          return {
            title: `${this.$t('Add')} <b>${this.formatCurrency(parseFloat(difference))}</b> ${this.$t('more.')}`,
            subtitle: `${this.$t('The minimum order capacity has not yet been reached, please add more goods.')}`,
            hasError: true,
            hasSucccess: false
          }
        } else if (this.grandTotalYourPrice > this.maxOrderCash) {
          return {
            title: `<b>${this.formatCurrency(this.maxOrderCash)}</b> ${this.$t('reached')}`,
            subtitle: `${this.$t('Minimum order price reached')}`,
            hasError: false,
            hasSucccess: true
          }
        }
      }
      return {
        title: '',
        subtitle: ''
      }
    },
    isEmptyTruck (): boolean {
      const emptyTypes = ['emptiesfulltruck_emptiesfulltruck']
      return emptyTypes.includes(this.query?.delivery_method)
    },
    isEmptyTransportType (): boolean {
      const emptyTypes = ['emptiespickup_emptiespickup', 'emptiesfulltruck_emptiesfulltruck']
      return emptyTypes.includes(this.query?.delivery_method)
    },
    isEmptyStandard (): boolean {
      const emptyTypes = ['emptiespickup_emptiespickup']
      return emptyTypes.includes(this.query?.delivery_method)
    },
    user () {
      return this.$store.state.auth.user
    },
    isWHSLUser () {
      const shipToIdFromStorage = this.shipTos[0]?.shiptosapid
      return this.user?.permissionSchema?.some((company) =>
        company.shipToId === shipToIdFromStorage &&
        company.rpDetails[0].rolename.includes('WHS')
      )
    }

  },
  methods: {
    getFillPercentage () {
      let fillPercentage = 0
      if (this.isEmptyTransportType) {
        const currentValue = this.progressBarMessage.hasSucccess ? this.progressBar?.iMaximumOrderPallet : this.progressBar?.iTotalCartPalletQty
        fillPercentage = currentValue / this.progressBar?.iMaximumOrderPallet * 100
      } else if (!this.progressBar?.isFullTruck) {
        // this.successWeight = this.maxOrderCash < this.grandTotalYourPrice
        // this.errorWeightBar = false
        fillPercentage = this.grandTotalYourPrice / this.maxOrderCash * 100
      } else {
        // this.errorWeight = (this.progressBar.iTotalCartWeight > this.progressBar.iMaximumOrderWeight) || (this.progressBar.iTotalCartPalletQty > this.progressBar.iMaximumOrderPallet) || (this.progressBar.iTotalCartPalletQty < this.progressBar.iMinimumOrderPallet && this.progressBar.iTotalCartWeight !== 0 && this.totalCartPallet !== 0)
        // this.errorWeightBar = (this.progressBar.iTotalCartWeight > this.progressBar.iMaximumOrderWeight) || (this.progressBar.iTotalCartPalletQty > this.progressBar.iMaximumOrderPallet)
        // this.successWeight = !this.errorWeight && ((this.progressBar.iTotalCartWeight > this.progressBar.iMinimumOrderWeight && this.progressBar.iTotalCartWeight < this.progressBar.iMaximumOrderWeight) || (this.progressBar.iTotalCartPalletQty >= this.minimumOrderPallett))
        const currentValue = this.progressBarMessage.hasSucccess ? this.progressBar.iMaximumOrderPallet : this.progressBar.iTotalCartPalletQty
        fillPercentage = currentValue / this.progressBar.iMaximumOrderPallet * 100
      }
      this.emitRequirementStatus()
      return fillPercentage
    },
    emitRequirementStatus () {
      const requirementMessage = this.progressBarMessage.buttonTitle
      this.$emit('requirement-status', requirementMessage)
    },
    emptiesDeliveryMethod () {
      const deliveryMethod = localStorage.getItem('shippingMethod')
      if (deliveryMethod === 'emptiespickup_emptiespickup') {
        return true
      }
    },
    handleProgressBarChange (newProgressBar: any) {
    // Logic when progressBar changes or on mount
      if (newProgressBar) {
        console.log('Progress Bar Data:', newProgressBar)
      }
    }
  },
  mounted () {
    this.handleProgressBarChange(this.progressBar)
  },
  watch: {
    progressBar (newVal, oldVal) {
      this.handleProgressBarChange(newVal)
    }
  }
})
