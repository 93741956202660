
import { defineComponent, PropType } from 'vue'
import AsahiPopup from '@/components/library/AsahiPopUp.vue'

export default defineComponent({
  name: 'AsahiDropDown',
  components: { AsahiPopup },
  data: () => ({
    openDropdown: false
  }),
  props: {
    dropdownName: String,
    options: {
      type: [Array] as PropType<Array<string> | string>,
      required: true
    },
    typeColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    borderType: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    popUpType: {
      type: String
    },
    routingPath: {
      type: String
    }
  },
  methods: {
    toggleDropdown () {
      this.openDropdown = !this.openDropdown
    },
    handleTitleClick () {
      if (this.routingPath) {
        this.$router.push(this.routingPath)
      } else {
        console.error('routingPath is not defined')
      }
    }
  }
})
