
<template>
  <div class="progress" :style="{height}" :class="{'white-border' : isWhite}">
    <div
      class="progress-bar"
      :class="{ 'success': success, 'error': error, 'white-bar': isWhite}"
      role="progressbar"
      :style="{width: fillWidth}"
      :aria-valuenow="fillWidth"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    barHeight: {
      type: Number,
      default: 10
    },
    percentage: {
      type: Number,
      required: true
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    error: Boolean,
    success: Boolean
  },
  computed: {
    fillWidth () {
      return this.percentage > '100' ? '100%' : this.percentage + '%'
    },
    height () {
      return this.barHeight + 'px'
    }
  }
}
</script>
<style lang="scss" scoped>

.white-border{
  border-color: white !important
}

.white-bar{
  background-color: white !important;
}

.progress {
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #3B3B3B;
  width: 100%;
  .progress-bar {
    background-color: #3B3B3B;
  }
  .success {
    background-color: #3A9D23;
  }
  .error {
    background-color: #c6243e;
  }
}

</style>
