<template>
  <span
    class="asahi-typography"
    :class="classObject"
  >
    <slot></slot>
  </span>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AsahiTypography',
  props: {
    type: {
      type: String,
      default: 'headline-big'
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    classObject () {
      return [this.type, this.color].join(' ')
    }
  }
})
</script>

<style scoped lang='scss'>
.asahi-typography {
  position: relative;
  font-family: $font-family-open-sans;
}

.headline-main {
  font-size: calc($font-size * 2);
  font-weight: $font-weight-above-medium; /* 700 */
  @include media-breakpoint-up(xl) {
    font-size: calc($font-size * 3);
  }
}

.headline-big {
  // font-size: calc(16px * 2.5); /* 40px @ base 16px */
  font-size: calc($font-size * 1.75);
  font-weight: $font-weight-above-medium;
  @include media-breakpoint-up(xl) {
    font-size: calc($font-size * 2.5);
  }
}
.headline-bigger {
  @extend .headline-big;
}

.headline-regular {
  // font-size: calc(16px * 1.5); /* 24px @ base 16px */
  font-size: calc($font-size * 1.5);
  font-weight: $font-weight-normal; /* 500 */
}

.headline-bold {
  // font-size: calc(16px * 1.5); /* 24px @ base 16px */
  font-size: calc($font-size * 1.5);
  font-weight: $font-weight-medium; /* 600 */
}

.headline-extra-bold {
  // font-size: calc(16px * 1.5); /* 24px @ base 16px */
  font-size: calc($font-size * 1.5);
  font-weight: $font-weight-above-medium; /* 700 */
}

.headline-1 {
  // font-size: calc(16px * 2); /* 32px @ base 16px */
  font-size: calc($font-size * 2);
  font-weight: $font-weight-above-medium; /* 700 */
}
.headline-2 {
  // font-size: calc(16px * 1); /* 16px @ base 16px */
  font-size: calc($font-size * 1);
  font-weight: $font-weight-above-medium; /* 700 */
}

.orange {
  color: $secondary-orange;
}
.headline-small {
  font-size: calc($font-size * 1);
  font-weight: $font-weight-regular;
}
.headline-3 {
  // font-size: calc(16px * 1); /* 16px @ base 16px */
  font-size: calc($font-size * 1);
  font-weight: $font-weight-high; /* 800 */
}

.title-1-regular {
  // font-size: calc(16px * 1.25); /* 20px @ base 16px */
  font-size: $font-size-xl;
  font-weight: $font-weight-regular; //400
}
.title-1-bold {
  // font-size: calc(16px * 1.25); /* 20px @ base 16px */
  font-size: $font-size-xl;
  font-weight: $font-weight-medium; //600
}
.title-1-extrabold {
  // font-size: calc(16px * 1.25); /* 20px @ base 16px */
  font-size: $font-size-xl;
  font-weight: $font-weight-above-medium; //700
}

.subtitle-light {
  font-size: calc($font-size * 1);/* 16px @ base 16px */
  font-weight: $font-weight-light; /* 300 */
}
.subtitle {
  font-size: calc($font-size * 1);/* 16px @ base 16px */
  font-weight: $font-weight-regular; /* 400 */
}

.subtitle-bold {
  font-size: calc($font-size * 1); /* 16px @ base 16px */
  font-weight: $font-weight-medium; /* 600 */
}

.subtitle-extrabold {
  font-size: calc($font-size * 1); /* 16px @ base 16px */
  font-weight: $font-weight-above-medium; /* 600 */
}

.product-bold-description {
  font-size: $font-size-sm; /* 16px @ base 16px */
  font-weight: $font-weight-medium; /* 600 */
}

.subtitle-extrabold {
  font-size: calc($font-size * 1); /* 16px @ base 16px */
  font-weight: $font-weight-above-medium; /* 700 */
}

.subtitle-1 {
  // font-size: calc(16px * .75); /* 12px @ base 16px */
  font-size: calc($font-size * .75);
  font-weight: $font-weight-regular; /* 400 */
}

.subtitle-1-line-through{
  text-decoration: line-through;
  font-size: calc($font-size * .75);
  font-weight: $font-weight-regular; /* 400 */
}
.subtitle-1-bold {
  // font-size: calc(16px * .75); /* 12px @ base 16px */
  font-size: calc($font-size * .75);
  font-weight: $font-weight-medium; /* 600 */
}
.subtitle-1-extrabold {
  // font-size: calc(16px * .75); /* 12px @ base 16px */
  font-size: calc($font-size * .75);
  font-weight: $font-weight-above-medium; /* 700 */
}
.subtitle-1-high {
  // font-size: calc(16px * .75); /* 12px @ base 16px */
  font-size: calc($font-size * .75);
  font-weight: $font-weight-high; /* 800 */
}
.subtitle-2-high {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-high; /* 800 */
}
.subtitle-2-extrabold {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-above-medium; /* 700 */
}
.subtitle-2-bold {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-medium; /* 600 */
}
.subtitle-2 {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-regular; /* 400 */
}
.subtitle-2-light {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-light; /* 300 */
}

.subtitle-3 {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-regular; /* 400 */
}
.subtitle-3-bold {
  // font-size: calc(16px * .875); /* 14px @ base 16px */
  font-size: calc($font-size * .875);
  font-weight: $font-weight-medium; /* 600 */
}
.body-1 {
  // font-size: calc(16px * .625); /* 10px @ base 16px */
  font-size: calc($font-size * .625);
}
.body-1-bold {
  // font-size: calc(16px * .625); /* 10px @ base 16px */
  font-size: calc($font-size * .625);
  font-weight: $font-weight-medium; /* 600 */
}
.body-2 {
  font-size: calc($font-size * 1); /* 16px */
  font-weight: $font-weight-regular; /* 400 */
}
.body-2-bold {
  font-size: calc($font-size * 1); /* 16px */
  font-weight: $font-weight-above-medium; /* 700 */
}
.body-2-menu {
  // font-size: calc(16px * .5); /* 8px @ base 16px */
  font-size: calc($font-size * .5); /* 8px */
}
.article-lead {
  // font-size: calc(16px * .5); /* 8px @ base 16px */
  font-size: calc($font-size * .5);
}
.article {
  // font-size: calc(16px * .375); /* 6px @ base 16px */
  font-size: calc($font-size * .375);
}
.button {
  // font-size: calc(16px * .5); /* 8px @ base 16px */
  font-size: calc($font-size * .5);
}
.caption {
  // font-size: calc(16px * .375); /* 6px @ base 16px */
  font-size: calc($font-size * .375);
}
.overline {
  // font-size: calc(16px * .25); /* 4px @ base 16px */
  font-size: calc($font-size * .5);
}

.headline-small-alt{
  font-size: calc($font-size * 1.25);
  font-weight: $font-weight-medium; /* 600 */
}

</style>
