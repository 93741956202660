import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: '/graphql'
})

function toNormalForm (value: any): string {
  if (typeof value === 'boolean') value = value.toString()
  if (typeof value === 'number') value = value.toString()

  if (typeof value === 'string') {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  return ''
}

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      outletid: toNormalForm(localStorage.getItem('shipToId')),
      datepopup: toNormalForm(localStorage.getItem('deliveryDate')),
      standarddeliverydate: toNormalForm(localStorage.getItem('standardDeliveryDate')),
      store: toNormalForm(localStorage.getItem('store')),
      outletdeliverymethod: toNormalForm(localStorage.getItem('shippingMethod')),
      authorization: `Bearer ${sessionStorage.getItem('id_token')}`,
      onlyEmpties: toNormalForm(localStorage.getItem('onlyEmpties')),
      noteForDriver: toNormalForm(localStorage.getItem('noteForDriver')),
      customerOrderNumber: toNormalForm(localStorage.getItem('customerOrderNumber')),
      lang: toNormalForm(localStorage.getItem('lang')),
      customertype: toNormalForm(localStorage.getItem('customertype')),
      productrecoenabled: toNormalForm(localStorage.getItem('productrecoenabled')),
      cartSKUs: toNormalForm(localStorage.getItem('cartSKUs'))
      // myfavourites: 1
    }
  }
})
const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})
